import React, { useEffect, useState } from "react";
import moment from "moment";
import { ChatRoom, SendMessageRequest, DeleteMessageRequest, DisconnectUserRequest } from "amazon-ivs-chat-messaging";
import { connect, useDispatch } from "react-redux";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import "../../css/chat.scss";
import placeholder from "../../images/user.png";
import { saveChat, banUser, deleteChat } from "../../redux/actions/chat";
import { Dropdown, Menu, notification, Input, Form, Modal, Button, } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";

function Chat({ myChatToken, eventId, chat, meeting, validate, getEventById, chatRoom, comments, setComments, connected }) {
  const dispatch = useDispatch();
  // const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  // const [connected, setConnected] = useState(false);
  // const [chatRoom, setChatRoom] = useState([]);

  const [banModal, setBanModal] = useState(false);
  const [banLoading, setBanLoading] = useState(false);
  const [banType, setBanType] = useState("")
  const [userId, setUserId] = useState("")

  const [form] = Form.useForm();

  const scrollToBottomMethod = useScrollToBottom();

  const scrollToBottom = () => {
    scrollToBottomMethod();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      sendChatMessage(value);
      setValue("");
    }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // useEffect(() => {
  //   if (chat) {
  //     setComments(chat);
  //   }
  // }, [chat]);

  useEffect(() => {
    // if (myChatToken?.token) {
    //   const room = new ChatRoom({
    //     regionOrUrl: "us-east-1",
    //     tokenProvider: () => ({
    //       token: myChatToken.token,
    //       sessionExpirationTime: new Date(myChatToken.sessionExpirationTime),
    //       tokenExpirationTime: new Date(myChatToken.tokenExpirationTime),
    //     }),
    //   });
    //   setChatRoom(room);
    //   // Connect to the chat room
    // }
  }, [myChatToken]);

  // useEffect(() => {
  //   // If chat room listeners are not available, do not continue
  //   if (!chatRoom.addListener) {
  //     return;
  //   }
  //   /**
  //    * Called when room is establishing the initial connection or reestablishing
  //    * connection after socket failure/token expiration/etc
  //    */
  //   const unsubscribeOnConnecting = chatRoom.addListener(
  //     "connecting",
  //     () => { }
  //   );

  //   /** Called when connection has been established. */
  //   const unsubscribeOnConnected = chatRoom.addListener("connect", (a, b) => {
  //     setConnected(true);
  //   });

  //   /** Called when a room has been disconnected. */
  //   const unsubscribeOnDisconnected = chatRoom.addListener(
  //     "disconnect",
  //     () => { }
  //   );

  //   /** Called when a chat message has been received. */
  //   const unsubscribeOnMessageReceived = chatRoom.addListener(
  //     "message",
  //     (message) => {
  //       console.log(message, "unsubscribeOnMessageReceived");

  //       // const data = {
  //       //   author: message?.attributes?.name,
  //       //   avatar: message?.attributes?.image,
  //       //   user_id: message?.attributes?.user_id,
  //       //   content: message.content,
  //       //   accountType: message?.attributes?.accountType,
  //       //   datetime: moment(message?.sendTime).format(),
  //       // };
  //       // setComments((preState) => [...preState, data]);
  //       setComments((preState) => [...preState, message])
  //       // {
  //       //   message?.attributes?.user_id === validate?.payload?.message?._id &&
  //       //     eventId &&
  //       //     setComments((preState) => [...preState, message])
  //       // }

  //       scrollToBottom();
  //       /* Example message:
  //        * {
  //        *   id: "5OPsDdX18qcJ",
  //        *   sender: { userId: "user1" },
  //        *   content: "hello world",
  //        *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
  //        *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de"
  //        * }
  //        */
  //     }
  //   );

  //   /** Called when a chat event has been received. */
  //   const unsubscribeOnEventReceived = chatRoom.addListener(
  //     "event",
  //     (event) => {
  //       console.log(event, "Called when a chat event has been received.");

  //       /* Example event:
  //        * {
  //        *   id: "5OPsDdX18qcJ",
  //        *   eventName: "customEvent,
  //        *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
  //        *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de",
  //        *   attributes: { "Custom Attribute": "Custom Attribute Value" }
  //        * }
  //        */
  //     }
  //   );

  //   /** Called when `aws:DELETE_MESSAGE` system event has been received. */
  //   const unsubscribeOnMessageDelete = chatRoom.addListener(
  //     "messageDelete",
  //     (deleteMessageEvent) => {
  //       console.log(deleteMessageEvent, "deleteMessageEvent");

  //       setComments(prevMessages => prevMessages.filter(m => m?.id !== deleteMessageEvent?.messageId && m?.payload?.Id !== deleteMessageEvent?.messageId));
  //       /* Example delete message event:
  //        * {
  //        *   id: "AYk6xKitV4On",
  //        *   messageId: "R1BLTDN84zEO",
  //        *   reason: "Spam",
  //        *   sendTime: new Date("2022-10-11T12:56:41.113Z"),
  //        *   requestId: "b379050a-2324-497b-9604-575cb5a9c5cd",
  //        *   attributes: { MessageID: "R1BLTDN84zEO", Reason: "Spam" }
  //        * }
  //        */
  //     }
  //   );

  //   /** Called when `aws:DISCONNECT_USER` system event has been received. */
  //   const unsubscribeOnUserDisconnect = chatRoom.addListener(
  //     "userDisconnect",
  //     (disconnectUserEvent) => {

  //       setComments(prevMessages => prevMessages.map(item => ({
  //         ...item,
  //         isban: disconnectUserEvent.userId == item?.attributes?.user_id ? item?.attributes?.user_id : item?.payload?.Attributes?.user_id
  //       })));

  //       // let banUser = comments.map(item => ({ ...item,
  //       //   isban: disconnectUserEvent.userId == item.attributes.user_id
  //       // }));

  //       // console.log(banUser,"banUser");

  //       // setComments(banUser)

  //       // const isban = {
  //       //   ...disconnectUserEvent,
  //       //    isban : true 
  //       // }

  //       // console.log(isban,"isban");

  //       // const allcomments = comments.findIndex(c => c.attributes.UserId === disconnectUserEvent.attributes.UserId)
  //       // comments[allcomments] = isban

  //       // setComments(comments);

  //       /* Example event payload:
  //        * {
  //        *   id: "AYk6xKitV4On",
  //        *   userId": "R1BLTDN84zEO",
  //        *   reason": "Spam",
  //        *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
  //        *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
  //        *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
  //        * }
  //        */
  //     }
  //   );

  //   chatRoom.connect();
  //   return () => {
  //     unsubscribeOnConnected();
  //     unsubscribeOnDisconnected();
  //     unsubscribeOnUserDisconnect();
  //     unsubscribeOnConnecting();
  //     unsubscribeOnMessageReceived();
  //     unsubscribeOnEventReceived();
  //     unsubscribeOnMessageDelete();
  //   };
  // }, [chatRoom]);

  const sendChatMessage = async (message) => {
    const content = `${message.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}`;
    const request = new SendMessageRequest(content);
    try {
      await chatRoom.sendMessage({
        ...request,
        Attributes: {
          user_id: validate?.payload?.message?._id,
          name: validate?.payload?.message?.nick_name ? validate?.payload?.message?.nick_name : validate?.payload?.message?.name,
          image: validate?.payload?.message?.profile_image_url,
          accountType: validate?.payload?.message?.account_type,
        },
      });
    } catch (error) {
      console.log(error, "error");
      // alert(JSON.stringify(error));
      // handleError(error);
    }
  };

  function dateToFromNowDaily(myDate) {
    var fromNow = moment(myDate).format("LLL");

    return moment(myDate).calendar(null, {
      lastWeek: "LLL",
      lastDay: "[Yesterday] h:mm A",
      sameDay: "[Today] h:mm A",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  const handleButtonClick = async (e, item) => {
    if (e.key === 'delete') {
      const data = {
        event_id: eventId,
        message_id: item?.id ? item?.id : item?.payload?.Id,
        reason: "delete",
      };

      const deleteRequest = new DeleteMessageRequest(item?.id ? item?.id : item?.payload?.Id);
      try {
        await chatRoom.deleteMessage(deleteRequest);
      } catch (error) {
        console.log(error, "error");
      }

      dispatch(deleteChat(data)).then((res) => {
        if (res.payload.success) {
          notification.success({
            message: 'Chat deleted Successfully',
            className: "toast-success",
          });

        } else {
          notification.error({
            message: res?.payload?.data?.message,
            className: "toast-error",
          });
        }
      });
    }
    if (e.key === 'eventban') {
      form.resetFields();
      setUserId(item?.attributes?.user_id ? item?.attributes?.user_id : item?.payload?.Attributes?.user_id);
      setBanType("chatban")
      setBanModal(true);
    }
    // if (e.key === 'globalban') {
    //   form.resetFields();
    //   setUserId(item?.attributes?.user_id);
    //   setBanType("permaban")
    //   setBanModal(true);
    // }
  };

  const handleCancel = () => {
    setBanModal(false);
    setBanLoading(false);
    setBanType("")
    setUserId("")
    form.resetFields();
  };

  const onFinish = async (val) => {
    const { reason } = val;
    setBanLoading(true);
    const formData = {
      reason: reason,
      event_id: eventId,
      user_id: userId,
      ban_type: banType
    }
    const request = new DisconnectUserRequest(userId, reason);
    try {
      await chatRoom.disconnectUser(request);
    } catch (error) {
      console.error(error);
    }
    dispatch(banUser(formData)).then((res) => {
      if (res.payload.success) {
        notification.success({
          message: res?.payload?.message,
          className: "toast-success",
          duration: 5,
        });
        setBanModal(false);
        setBanLoading(false);
        setBanType("")
        setUserId("")
        // form.resetFields();
      } else {
        setBanLoading(false);
        notification.error({
          message: res?.payload?.data?.message,
          className: "toast-error",
        });
      }
    });
  };


  if (!connected) {
    return (
      <div className="chat-loading">
        <h3>...loading</h3>
      </div>
    );
  }
  return (
    <div className="live-chat-comp">
      <div id="frame">
        <div className="content">
          <ScrollToBottom className="messages">
            <ul className="message-ul">
              {comments.map((it) => {
                return (
                  <>
                    {it?.payload ?
                      <li
                        className={
                          validate?.payload?.message?._id === it?.payload?.Attributes?.user_id ? "replies" : "sent"
                        }
                      >
                        <img src={it?.payload?.Attributes?.image ? it?.payload?.Attributes?.image : placeholder} alt="" />
                        <div className="msg-c-box message-box-main">
                          {validate?.payload?.message?._id !== it?.payload?.Attributes?.user_id ? (
                            <>
                              <span class="user-name">{it?.payload?.Attributes?.name} <span title={`Reason: ${it?.ban_reason}`} style={{ fontSize: '10px', color: 'red' }}>{it?.isban && '(Ban)'}</span></span>
                              <p className="msg-content chat-drowpdown">
                                <span class="user-message">{it?.payload?.Content}</span>
                                <Dropdown.Button
                                  trigger="click"
                                  // className="chat-drowpdown"
                                  // size="large"
                                  size="small"
                                  overlay={
                                    <Menu
                                      onClick={(e) =>
                                        handleButtonClick(e, it)
                                      }
                                      icon={faEllipsisVertical}
                                    >

                                      <Menu.Item
                                        key="delete"
                                      >
                                        <span>Delete</span>
                                      </Menu.Item>

                                      {((getEventById?.payload?.message?.[0]?.hosts?.find(item => item.primary === true)?.user_id !== it?.payload?.Attributes?.user_id) && !it?.isban) && <Menu.Item
                                        key="eventban"
                                        disabled={it?.isban}
                                        title={it?.isban ? "This User is Banned" : ""}
                                      >
                                        <span>{it?.isban ? "This User is Banned" : "Ban User"}</span>
                                      </Menu.Item>}
                                      {/* <Menu.Item
                                    key="globalban"
                                    disabled={it?.isban}
                                    title={it?.isban ? "Banned" : ""}
                                  >
                                    <span>Global Ban</span>
                                  </Menu.Item> */}
                                    </Menu>
                                  }
                                ></Dropdown.Button>
                              </p>
                            </>
                          ) : (
                            <p className="msg-content chat-drowpdown">
                              <span class="user-message">{it?.payload?.Content}</span>
                              <Dropdown.Button
                                trigger="click"
                                // className="chat-drowpdown"
                                // size="large"
                                size="small"
                                overlay={
                                  <Menu
                                    onClick={(e) =>
                                      handleButtonClick(e, it)
                                    }
                                    icon={faEllipsisVertical}
                                  >
                                    <Menu.Item
                                      key="delete"
                                    >
                                      <span>Delete</span>
                                    </Menu.Item>

                                  </Menu>
                                }
                              ></Dropdown.Button>
                            </p>
                          )}
                          <div className="msg-date-main">
                            <small className="msg-date">
                              {dateToFromNowDaily(it?.sendTime)}
                            </small>
                          </div>
                        </div>
                      </li>
                      :
                      <li
                        className={
                          validate?.payload?.message?._id === it?.attributes?.user_id ? "replies" : "sent"
                        }
                      >
                        <img src={it?.attributes?.image ? it?.attributes?.image : placeholder} alt="" />
                        <div className="msg-c-box message-box-main">
                          {validate?.payload?.message?._id !== it?.attributes?.user_id ? (
                            <>
                              <span class="user-name">{it?.attributes?.name} <span title={`Reason: ${it?.ban_reason}`} style={{ fontSize: '10px', color: 'red' }}>{it?.isban && '(Ban)'}</span></span>
                              <p className="msg-content chat-drowpdown">
                                <span class="user-message">{it.content}</span>
                                <Dropdown.Button
                                  trigger="click"
                                  // className="chat-drowpdown"
                                  // size="large"
                                  size="small"
                                  overlay={
                                    <Menu
                                      onClick={(e) =>
                                        handleButtonClick(e, it)
                                      }
                                      icon={faEllipsisVertical}
                                    >
                                      <Menu.Item
                                        key="delete"
                                      >
                                        <span>Delete</span>
                                      </Menu.Item>
                                      {
                                        // check host 
                                        ((getEventById?.payload?.message?.[0]?.hosts?.find(item => item.primary === true)?.user_id !== it?.attributes?.user_id) && !it?.isban) &&
                                        <Menu.Item
                                          key="eventban"
                                          disabled={it?.isban}
                                          title={it?.isban ? "This User is Banned" : ""}
                                        >
                                          <span>{it?.isban ? "This User is Banned" : "Ban User"}</span>
                                        </Menu.Item>}
                                      {/* <Menu.Item
                                    key="globalban"
                                    disabled={it?.isban}
                                    title={it?.isban ? "Banned" : ""}
                                  >
                                    <span>Global Ban</span>
                                  </Menu.Item> */}
                                    </Menu>
                                  }
                                ></Dropdown.Button>
                              </p>
                            </>
                          ) : (
                            <p className="msg-content chat-drowpdown">
                              <span class="user-message">{it?.content}</span>
                              <Dropdown.Button
                                trigger="click"
                                // className="chat-drowpdown"
                                // size="large"
                                size="small"
                                overlay={
                                  <Menu
                                    onClick={(e) =>
                                      handleButtonClick(e, it)
                                    }
                                    icon={faEllipsisVertical}
                                  >
                                    <Menu.Item
                                      key="delete"
                                    >
                                      <span>Delete</span>
                                    </Menu.Item>

                                  </Menu>
                                }
                              ></Dropdown.Button>
                            </p>
                          )}
                          <div className="msg-date-main">
                            <small className="msg-date">
                              {dateToFromNowDaily(it?.sendTime)}
                            </small>
                          </div>
                        </div>
                      </li>}
                  </>
                );
              })}
            </ul>
          </ScrollToBottom>
          <div className="message-input">
            <div className="wrap">
              <form onSubmit={handleSubmit} className="chat-message-input-box">
                <input
                  className="message-field"
                  value={value}
                  type="text"
                  placeholder="Write your message..."
                  onChange={handleChange}
                />
                <button
                  onSubmit={handleSubmit}
                  className="submit message-button"
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {banModal && (
        <Modal
          className="modal-generic modal-400"
          centered
          visible={banModal}
          // onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <Form
            // layout="vertical"
            form={form}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div class="row">
              <div className="col-md-12">
                <h2 className="modal-hd1">
                  {banType === "chatban" ? "Are you Sure You want to ban the user from this event?" : "Are you sure you want to ban the user globally?"}
                </h2>
              </div>
            </div>

            <div class="row">
              <div className="col-md-12 modal-review-right">
                <div className="form-styling">
                  <Form.Item
                    name="reason"
                    labelCol={{ span: 24 }}
                    label="Reason to Ban"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the reason",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the reason"
                      size="large"
                    />
                  </Form.Item>
                </div>

              </div>
            </div>

            <div class="row">
              <div className="col-12">
                <div className="d-flex justify-content-md-end">
                  <Button
                    className="default-btn fw-normal d-flex align-items-center justify-content-center"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={banLoading}
                  >
                    Ban
                  </Button>
                  <Button
                    key="back"
                    className="default-btn  fw-normal outline d-flex align-items-center justify-content-center"
                    onClick={handleCancel}
                  >
                    Exit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
}
function mapStateToProps({ myChatToken, validate }) {
  return { myChatToken, validate };
}
export default connect(mapStateToProps)(Chat);
