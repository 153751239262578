import React, { useState } from 'react';
import { Tabs } from 'antd';
import "../../css/review.scss";
import '../../css/analytics.scss';
import "../..";
import EventUsersReviewComments from './eventUsersReviewComments';
import EventUsersComments from './eventUsersComments';


const { TabPane } = Tabs;

export default function UserComments() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [title, setTitle] = useState(
    searchParams.get('type') === null ? 'shows' : searchParams.get('type')
  );
  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setTitle(key);
  };

  return (
    <>
      <div className="grid-listing-area flex-column tabs-overflow-scroll scrollbar-style flex-sm-row align-items-start align-items-sm-center mb-10">
        <div className="grid-listing-left flex-grow-1">
          <Tabs
            // defaultActiveKey="allvideos"
            defaultActiveKey={title}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Shows" key="shows"></TabPane>
            <TabPane tab="Reviews" key="reviews"></TabPane>
          </Tabs>
        </div>
      </div>

      {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

      <div className={`upcoming-events-wrapper mb-30`}>
        {title === 'shows' && <EventUsersComments />}
        {title === 'reviews' && <EventUsersReviewComments />}
        {/* {title === 'analytics' && "analytics"} */}
      </div>
    </>
  );
}
