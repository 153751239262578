import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input, InputNumber } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  customerorderHistory,
  details,
  getPurchaser,
  getReferrer,
} from "../../redux/actions/purchaseHistory.action";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
const { Option } = Select;
let arrHost = [];
const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState("none");
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [load, setLoad] = useState(false);
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [order, setOrder] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [groupBy, setGroupBy] = useState("Order");
  const [groupByTable, setGroupByTable] = useState("Order");
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const { shopifyCustomerorder, detailsOrder, savedGeneralCategories, savedCategory, savedSubCategory } = useSelector(
    (state) => {
      return state;
    }
  );

  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  useEffect(() => {
    setSearchIDLoad(true)
    dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
      setSearchIDLoad(false)
      if (res?.success) {
        setSearchDataIDs(res?.message)
      }
    })
    setLoading(true);
    setGeneralLoad(true)
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGeneralLoad(false)
      } else {
        setGeneralLoad(false)
      }
    })

    dispatch(
      customerorderHistory(1, limit, startDate, endDate, gender, category, subCategory, source, "", "", "", "", "Order")
    ).then((res) => {
      setTotalEvent(res?.message?.total_records);
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {

    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(
      customerorderHistory(
        page + 1,
        limit,
        startDate,
        endDate,
        gender,
        category,
        subCategory,
        source,
        customer[0]?.value,
        order,
        referredBy,
        purchaseBy,
        groupBy,
        searchID,
        idType
      )
    ).then((res) => {
      setLoading(false);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setGroupByTable(groupBy)
    setLoading(true);
    setStatus(true);
    dispatch(
      customerorderHistory(
        1,
        limit,
        startDate,
        endDate,
        gender,
        category,
        subCategory,
        source,
        customer[0]?.value,
        order,
        purchaseBy,
        referredBy,
        groupBy,
        searchID,
        idType
      )
    ).then((res) => {
      setLoading(false);
      setTotalEvent(res?.message?.total_records);
      setCurrentPage(0);
    });
    // setSubmit(groupBy);
  };

  const detailView = (orderDetail) => {
    setModal(true);
    setLoad(true);
    dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then(
      (res) => {
        setLoad(false);
      }
    );
  };

  const handleReset = (res) => {
    setIdType("event")
    setSearchID()
    setGroupByTable("Order")
    setSource("all");
    setSubmit("none");
    setLoading(true);
    setCustomer([]);
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setCurrentPage(0);
    setOrder(null)
    setPurchaseBy(null)
    setReferredBy(null)
    setGroupBy("Order")
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    dispatch(
      customerorderHistory(
        1,
        limit,
        moment().startOf("year").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
        "",
        "",
        "",
        "all",
        "",
        "",
        "",
        "",
        "Order"
      )
    ).then((res) => {
      setLoading(false);
      setTotalEvent(res?.message?.total_records);
    });
  };

  const loadOptions = async (input, callback) => {
    await smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    });

    callback(result);
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {

        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null)
    setSubCategory(null)
    setCatLoad(true)
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false)
      } else {
        setCatLoad(false)
      }
    })

  };

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null)
    setSubCatLoad(true)
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false)
      } else {
        setSubCatLoad(false)
      }
    })
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const handleSource = (e) => {
    setSource(e);
  };

  const handleFilterPurchaseBy = (e) => {

    setPurchaseLoad(true)
    dispatch(getPurchaser(e)).then((res) => {
      setPurchaseLoad(false)

      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

  }

  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleFilterReferredBy = (e) => {
    setReferrerLoad(true)
    dispatch(getReferrer(e)).then((res) => {
      setReferrerLoad(false)
      if (res?.success) {
        setsearchReferredBy(res?.message)
      }
    })

  }
  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);



  function DefaultTable() {
    let data = shopifyCustomerorder?.payload?.message?.data;
    if (data) {
      switch (groupByTable) {
        case "Order":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">User Id</th>
                    <th className="td-width-120">User Name</th>
                    <th className="td-width-100">User Email</th>
                    <th className="td-width-200">Event Name</th>
                    <th className="td-width-80">Event Id</th>
                    <th className="td-width-100">Ban Reason</th>
                    <th className="td-width-100">	Ban Date</th>
                    <th className="td-width-100">	Un Ban</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {" "}
                            {moment(item?.created_date).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <a
                              className="open-modal blue-link"
                              onClick={() => {
                                detailView(item);
                              }}
                            >
                              {item?.order_id ? item?.order_id : "-"}
                            </a>
                          </td>
                          <td>
                            {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                          </td>
                          <td>
                            {item?.category?.category_name ? item?.category?.category_name : "All"}
                          </td>

                          <td>
                            {!item?.event && "-"}
                            {item?.event === "productshare" && "Website Sales"}
                            {item?.event === "reviewshop" && "Review Sales"}
                            {item?.event === "reviewshare" && "Review Sales"}
                            {item?.event === "eventshop" && "Event Sales"}
                            {item?.event === "eventshare" && "Event Sales"}
                          </td>
                          <td>
                            {item?.event === "productshare"
                              ? "Anonymous"
                              : (item?.customer?.name ? item?.customer?.name : "-")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data?.length +
                            currentPage * 15}
                        </b>
                      </td>
                      <td></td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>


                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "date":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Order Date</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {" "}
                            {moment(item?.created_date).format("DD-MM-YYYY")}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}

                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>

                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "genCategory":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Department</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}

                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>

                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "category":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Category</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.category?.category_name ? item?.category?.category_name : "All"}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}
                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>

                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "subCategory":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Sub Category</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}
                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>

                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "source":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Source</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {!item?.event && "-"}
                            {item?.event === "productshare" && "Website Sales"}
                            {item?.event === "reviewshop" && "Review Sales"}
                            {item?.event === "reviewshare" && "Review Sales"}
                            {item?.event === "eventshop" && "Event Sales"}
                            {item?.event === "eventshare" && "Event Sales"}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}
                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>

                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>

                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        case "purchased":
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Purchaser</th>
                    <th className="td-width-150">Total Orders</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {item?.event === "productshare"
                              ? "Anonymous"
                              : (item?.customer?.name ? item?.customer?.name : "-")}
                          </td>
                          <td>

                            {item?.order_count ? item?.order_count : "-"}
                          </td>

                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>

                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                b.order_count ? b.order_count : 0.0
                              )
                            );
                          }, 0)
                          }
                        </b>
                      </td>

                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>


                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );
        default:
          return (<>
            <div className="table-responsive scrollbar-style">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-150">Order Date</th>
                    <th className="td-width-150">Order #</th>
                    <th className="td-width-150">Department</th>
                    <th className="td-width-150">Category</th>
                    <th className="td-width-150">Sub Category</th>
                    <th className="td-width-150">Source</th>
                    <th className="td-width-150">Purchaser</th>
                    <th className="td-width-150 text-right">Amount</th>
                    <th className="td-width-150 text-right">Return Amount</th>
                    <th className="td-width-150 text-right">Balance Amount</th>
                    <th className="td-width-150 text-right">ORME Fee</th>
                    <th className="td-width-150 text-right">Cashback</th>
                    <th className="td-width-150 text-right">Referral Fee</th>
                    <th className="td-width-150 text-right">Review Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {shopifyCustomerorder.loading ? (
                    <div className="antd-loading-ift">
                      <Spin size="large"></Spin>
                    </div>
                  ) : shopifyCustomerorder?.payload?.message?.data.length ===
                    0 ? (
                    <tr>
                      <td colspan="12">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  ) : (
                    data?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1 + currentPage * 15}</td>
                          <td>
                            {" "}
                            {moment(item?.created_date).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <a
                              className="open-modal blue-link"
                              onClick={() => {
                                detailView(item);
                              }}
                            >
                              {item?.order_id ? item?.order_id : "-"}
                            </a>
                          </td>
                          <td>
                            {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                          </td>
                          <td>
                            {item?.category?.category_name ? item?.category?.category_name : "All"}
                          </td>
                          <td>
                            {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                          </td>
                          <td>
                            {!item?.event && "-"}
                            {item?.event === "productshare" && "Website Sales"}
                            {item?.event === "reviewshop" && "Review Sales"}
                            {item?.event === "reviewshare" && "Review Sales"}
                            {item?.event === "eventshop" && "Event Sales"}
                            {item?.event === "eventshare" && "Event Sales"}
                          </td>
                          <td>
                            {item?.event === "productshare"
                              ? "Anonymous"
                              : (item?.customer?.name ? item?.customer?.name : "-")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.order_totalprice).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.returned_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.balance_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>

                          <td className="text-right">
                            {numeral(item?.cashback_amount).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.customer_commission).format("$0,0.0'")}
                          </td>
                          <td className="text-right">
                            {numeral(item?.creator_commission).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {shopifyCustomerorder?.payload?.message?.data.length > 0 && (
                    <tr>
                      <td></td>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          {shopifyCustomerorder?.payload?.message?.data?.length +
                            currentPage * 15}
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_totalprice ? b.order_totalprice : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.returned_amount ? b.returned_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.balance_amount ? b.balance_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.cashback_amount ? b.cashback_amount : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.customer_commission
                                    ? b.customer_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>
                      <td className="text-right">
                        <b>
                          {numeral(
                            data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.creator_commission
                                    ? b.creator_commission
                                    : 0.0
                                )
                              );
                            }, 0)
                          ).format("$0,0.0'")}
                        </b>
                      </td>


                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
          );

      }
    }
  }

  return (
    <>
      <div className="sales-page-main">
        {/* <div class="title-area mb-10">
          <h1>Orders</h1>
        </div> */}
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],
                    // "Last Year": [
                    //   moment().startOf("year"),
                    //   moment().subtract(1, "year").endOf("year"),
                    // ],
                    // "This Year": [moment().startOf("year"), moment()],
                    // "Last Month": [
                    //   moment().subtract(1, "month").startOf("month"),
                    //   moment().subtract(1, "month").endOf("month"),
                    // ],
                    // "This Month": [
                    //   moment().startOf("month"),
                    //   moment().endOf("month"),
                    // ],
                    // Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>




              {/* <div className="col-xl-3 col-md-6 col-12 mb15">
                <p className="mb-5rem">Purchased By</p>
                <div onPaste={(e) => handleOnPaste(e)}>
                  <AsyncSelect
                    isMulti={false}
                    cacheOptions
                    isClearable={true}
                    required
                    //isDisabled={this.state.status}
                    defaultOptions
                    loadOptions={loadOptions}
                    placeholder="Enter Customer Name"
                    name={"host"}
                    value={customer}
                    className="field-style2 search-field"
                    onChange={(e, options) => {
                      handleMultiSelect(e, options);
                    }}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue ? "Referred By" : "No Referral Found"
                    }
                    // formatOptionLabel={this.formatOptionLabel}
                  />
                </div>
              </div> */}


              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              // <div className="antd-loading-ift">
              // 	<Spin size="large" />
              // </div>
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {submit === "none" && <DefaultTable />}
                {/* {submit === "date" && <DateGroupTable />} */}
                {/* {submit === "category" && <CategoryGroupTable />}
                {submit === "brand" && <BrandGroupTable />} */}
                <div className="purchase-data referral-data">
                  {shopifyCustomerorder?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={shopifyCustomerorder?.payload?.message?.next?.page}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>

              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="code-activated-popup"
        size="lg"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
        backdrop="static"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load ? (
            <span className="mt-2 mr-2 loader-center position-relative">
              <Spin size="large" />
            </span>
            // <Spin
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //   }}
            // />
          ) : (
            <>
              <div className="order-detail-title-area">
                <div className="d-flex flex-column">
                  <div className="order-num">
                    Order #
                    <div className="order-name">
                      {
                        detailsOrder?.payload?.message?.order_detail
                          ?.order_number
                      }
                    </div>
                  </div>
                  <duv className="order-num order-name-main">
                    Customer Name:
                    <div className="order-name">
                      {detailsOrder?.payload?.message?.order_detail?.customer
                        ?.first_name +
                        " " +
                        detailsOrder?.payload?.message?.order_detail?.customer
                          ?.last_name}
                    </div>
                  </duv>
                </div>

                <span className="order-date">
                  {moment
                    .utc(
                      detailsOrder?.payload?.message?.order_detail?.created_at
                    )
                    .format("MMMM Do YYYY [from Online Store]")}
                </span>
              </div>
              <Table responsive size="sm" className="transactions-box">
                <thead className="table_heading">
                  <tr>
                    <th className="td-width-60">S.#</th>
                    <th className="td-width-120">Order Date</th>
                    <th className="td-width-100">SKU #</th>
                    <th className="td-width-200">Description</th>
                    <th className="td-width-80">Qty</th>
                    <th className="td-width-100 text-right">Price</th>
                    <th className="td-width-100 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {!detailsOrder?.loading &&
                    detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                      (item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {moment
                                .utc(order?.created_at)
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.sku}</td>
                            <td>{item?.title}</td>
                            <td>{item?.quantity}</td>
                            <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(
                                getSum(item?.quantity, item?.price)
                              ).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
              <div className="order-sum">
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Subtotal</div>
                    <div className="left-info">
                      {
                        detailsOrder?.payload?.message?.order_detail?.line_items
                          ?.length
                      }{" "}
                      item
                    </div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Discount</div>
                    {detailsOrder?.payload?.message?.order_detail
                      ?.total_discounts
                      ? numeral(
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_discounts /
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_line_items_price
                      ).format("0%")
                      : ""}
                  </div>
                  {detailsOrder?.payload?.message?.order_detail
                    ?.total_discounts ? (
                    <>
                      <div className="right-txt">
                        -
                        {numeral(
                          detailsOrder?.payload?.message?.order_detail
                            ?.total_discounts
                        ).format("$0,0.0'")}
                      </div>
                    </>
                  ) : (
                    <div className="right-txt">
                      {numeral(0).format("$0,0.0'")}
                    </div>
                  )}
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Shipping</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_shipping_price_set?.presentment_money?.amount
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Tax</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_tax
                    ).format("$0,0.0'")}
                  </div>
                </div>
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd fw-bold">Total Amount</div>
                  </div>
                  <div className="right-txt fw-bold">
                    {numeral(
                      detailsOrder?.payload?.message?.order_detail?.total_price
                    ).format("$0,0.0'")}
                  </div>
                </div>
              </div>
              {!detailsOrder?.loading &&
                detailsOrder?.payload?.message?.order_return_detail && (
                  <div style={{ marginTop: "20px" }}>
                    <div className="order-detail-title-area">
                      <div className="d-flex flex-column">
                        <div className="order-num">Refund Detail</div>
                      </div>
                    </div>
                    <Table responsive size="sm" className="transactions-box">
                      <thead className="table_heading">
                        <tr>
                          <th>S#</th>
                          <th>Order Date</th>
                          <th>SKU #</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!detailsOrder?.loading &&
                          detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.map(
                            (item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {moment
                                      .utc(order?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.line_item?.sku}</td>
                                  <td>{item?.line_item?.title}</td>
                                  {/* <td>{item?.line_item?.quantity}</td>
                              <td>
                                {numeral(
                                  getSum(item?.subtotal, item?.quantity)
                                ).format("$0,0.0'")}
                              </td> */}
                                  <td>{item?.quantity}</td>
                                  <td>
                                    {numeral(
                                      item?.line_item?.price
                                    ).format("$0,0.0'")}
                                  </td>
                                  <td>
                                    {numeral(item?.subtotal).format("$0,0.0'")}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </Table>
                    <div className="order-sum">
                      <>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Subtotal</div>
                            <div className="left-info">
                              {detailsOrder?.payload?.message?.order_return_detail
                                ?.refund_line_items?.length
                                ? detailsOrder?.payload?.message
                                  ?.order_return_detail?.refund_line_items?.length
                                : 0}{" "}
                              item
                            </div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd">Tax</div>
                          </div>
                          <div className="right-txt">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                        <div className="paid-mid-row">
                          <div className="left-txt multi-content">
                            <div className="left-hd fw-bold">Total Amount</div>
                          </div>
                          <div className="right-txt fw-bold">
                            {numeral(
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.subtotal);
                                },
                                0
                              ) +
                              detailsOrder?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                                (acc, item) => {
                                  return (acc = acc + item.total_tax);
                                },
                                0
                              )
                            ).format("$0,0.0'")}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setModal(false)}
            className="default-btn outline padb-3"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
            Back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
