import React, { useEffect, useState } from 'react';
import { useStepsForm } from 'sunflower-antd';
import { Button, Form, Input, message, Spin } from 'antd';
import '../../css/basicSetup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import placeholderImage from '../../images/placeholder-image.jpg';
import { getUserInfo } from '../../redux/actions/userInfo.action';
import axios from 'axios';
import { basicProfileImage } from '../../redux/actions/profileImageBasic.action';
import { notification } from 'antd';
import { SetLocalDescriptionTask } from 'amazon-chime-sdk-js';
import { basicPassChange } from '../../redux/actions/basicPassChange.action';
import { getSocialFollowers } from '../../redux/actions/socialFollowers.action ';
import { createBrowserHistory } from 'history';
import { updateProfileBio } from '../../redux/actions/basicProfileFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import GroupIcon from '@mui/icons-material/Group';
import numeral from 'numeral';
import { store } from '../../redux/store';
import { AUTHENTICATE } from '../../redux/types/types';
import { visitorURL } from '../../config';

export const history = createBrowserHistory();
export default function BasicSetup({ reRender }) {
  const { TextArea } = Input;

  const dispatch = useDispatch();
  const { userInfo, validate, socialMediaFollowers, checkInstagramConnection } = useSelector((state) => state);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [instaData, setInstaData] = useState([]);
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({});
  const handleValues = (e) => {
    form.setFieldsValue({ username: e.target.value });
  };

  useEffect(() => {
    setLoading(true)
    dispatch(getUserInfo()).then((res) => {
      if (res.success) {
        form.setFieldsValue({
          username: res?.message?.data?.name,
          notes: res?.message?.data?.bio,
          website_address: res?.message?.data?.website_address,
        });
        if (checkInstagramConnection?.payload?.connection) {
          dispatch(getSocialFollowers(res?.message?.data?._id)).then((r) => {
            if (r?.payload?.success) {
              setInstaData(r?.payload?.message)
              setLoading(false)
            }
            else {
              setLoading(false)
            }
          });
        } else {
          setLoading(false)
        }
      }
    });

  }, []);




  const formList = [
    <>
      <Form.Item
        label="Enter Name"
        name="username"
        labelCol={{ span: 24 }}
        initialValue={form.getFieldValue().username}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please input your Name',
        //   },
        // ]}
        onChange={handleValues}
      >
        <Input size="large" placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Bio"
        name="notes"
        initialValue={form.getFieldValue().notes}
        onChange={(e) => form.setFieldsValue({ notes: e.target.value })}
      >
        <TextArea
          showCount
          maxLength={200}
          className="dark-field"
          placeholder="Enter Bio"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        label="Enter Website Address"
        name="website_address"
        initialValue={form.getFieldValue().website_address}
        onChange={(e) => form.setFieldsValue({ website_address: e.target.value })}
        rules={[
          {
            type: 'url',
            // warningOnly: true,
          },
          // {
          //   type: 'string',
          //   min: 6,
          // },
        ]}
      >
        <Input size="large" placeholder="Enter Website Address" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={loader}
        className="default-btn ml-0 fw-normal"
      // onClick={saveBioData}
      // disabled={!validURL}
      >
        Save
      </Button>
    </>,
  ];
  const onChangeInputImage = (e) => {
    if (e.target.files.length === 1) {
      if (
        e.target.files[0]?.type === 'image/jpeg' ||
        e.target.files[0]?.type === 'image/webp' ||
        e.target.files[0]?.type === 'image/png' ||
        e.target.files[0]?.type === 'image/svg+xml'
      ) {
        const files = [];
        const reader = new FileReader();
        files.push(e.target.files[0]);
        reader.onloadend = () => {
          files[0].preview = reader.result;
          files[0].toUpload = true;
          setImage(files);
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        notification.error({
          message: 'We Only Support PNG, WEBP, Or JPG Image!',
          className: 'toast-error',
        });
      }
    }
  };

  const clearImage = () => {
    setImage([]);
  };

  const uploadImage = async () => {
    setLoading(true)
    var formData = new FormData();
    formData.append('image', image?.[0]);
    formData.append('instagram_username', form.getFieldValue().username);
    dispatch(basicProfileImage(formData, validate?.payload?.message?._id)).then((res) => {
      if (res.success) {
        dispatch(getUserInfo()).then((res) => {
          setImage([]);

        })
        notification.success({
          message: 'Image Has Been Uploaded!',
          className: 'toast-success',
        });
        setLoading(false)
      }

    }).catch(err => {
      setLoading(false)
    });
  };
  const handlePassword = () => {

    setLoad(true)
    if (form.getFieldValue().newPassword) {
      if (form.getFieldValue().newPassword === form.getFieldValue().changepassword) {
        dispatch(basicPassChange(form.getFieldValue().currentPassword, form.getFieldValue().newPassword, validate?.payload?.message?._id)).then((res) => {
          if (res.success) {
            notification.success({
              message: 'Password Has Been Changed!',
              className: 'toast-success',
            });
            localStorage.removeItem("token");
            localStorage.removeItem("sc_token");
            store.dispatch({ type: AUTHENTICATE, payload: {} })
            setLoad(false)
          }
        }).catch(err => {
          setLoad(false)
          notification.error({
            message: err.message,
            className: 'toast-error',
          });
        });
      }
      else {
        setLoad(false)
        notification.error({
          message: 'New Passwords Do Not Match!',
          className: 'toast-error',
        });
      }
    } else {
      setLoad(false)
      notification.error({
        message: 'Password Field is empty!',
        className: 'toast-error',
      });
    }
  };

  useEffect(() => {
    reRender(userInfo)
  }, [userInfo])

  const saveBioData = () => {
    setLoader(true)
    dispatch(updateProfileBio(form.getFieldValue().username, form.getFieldValue().notes, form.getFieldValue().website_address, validate?.payload?.message?._id)).then(res => {
      if (res.success) {
        notification.success({
          message: 'Profile Data Updated!',
          className: 'toast-success',
        });
        // history.go(0)
        setLoader(false)
        dispatch(getUserInfo())
        // .then((res) => {
        // if (res.success) {
        //   form.setFieldsValue({
        //     username: res?.message?.data?.name,
        //     notes: res?.message?.data?.bio,
        //   });
        // }
        // });
      }
      setLoader(false)
    })
  }

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num >= 1000) return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
  }
  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
  };
  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Basic Setup</h1>
      </div>
      <div className="container p-0 ml-0">
        <div className="row">
          <div className="profile_box_main col-md-6 mb-20">
            <div className="stream-box-white mb-0 h-100">
              <div className="dp_cont mb-5 align-items-baseline">
                <span>
                  <img
                    alt="..."
                    src={
                      image?.[0]?.preview
                        ? image?.[0]?.preview
                        : (userInfo?.message?.data?.profile_image_url
                          ? userInfo?.message?.data?.profile_image_url
                          : placeholderImage)
                    }
                    // key={`img-id-${idx.toString()}`}
                    style={{ width: '76px', height: '76px' }}
                    className="circle profile-icon"
                  />
                  <strong>Logo</strong>
                  {/* <img src={avatar} alt="Profile" /> */}
                </span>
                <div className='d-flex flex-column dp_buttons_container'>
                  <div className="dp_buttons ml-0 pt-6">
                    <input
                      accept=".jpg, .jpeg, .png, .webp, .gif"
                      onChange={onChangeInputImage}
                      onClick={(e) => e.target.value = null}
                      id="fileupload5"
                      type="file"
                      name="file"
                      className="d-none"
                    />
                    {/* <Button
                    accept=".jpg, .jpeg, .png, .webp, .gif"
                    onChange={onChangeInputImage}
                    type="file"
                    color="default"
                    className="select-image profile-btn "
                  > */}

                    <label htmlFor="fileupload5" className="select-image profile-btn text-center cursor-pointer">
                      Change Image
                    </label>

                    {/* </Button> */}
                    <Button
                      onClick={uploadImage}
                      className="d-block upload-btn profile-btn color-secondary"
                      disabled={image.length > 0 ? false : true}
                      loading={loading}
                    >
                      Save
                    </Button>

                    <Button
                      onClick={clearImage}
                      disabled={image.length > 0 ? false : true}
                      type="button"
                      color="default"
                      className="select-image profile-btn color-secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className='mt-2 logo-image-dimension'>
                    Accepted file format: JPG, JPEG, PNG, WEBP, GIF
                    <br />
                    Recommended dimensions: 110 x 110 pixels
                  </div>
                </div>
              </div>
              {/* <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Instagram Following: </div>
                  <div className='followers-box'>
                    <div className='followers-icon'>
                      <GroupIcon sx={{ color: 'gray' }} />
                    </div>
                    <div className='followers-count'>{social?.follows_count ? numberFormat(social?.follows_count) : 0}</div>
                  </div>

                </div>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Instagram Followers:</div>
                  <div className='followers-box'>
                    <div className='followers-icon'>
                      <GroupIcon sx={{ color: 'gray' }} />
                    </div>
                    <div className='followers-count'>{social?.followers_count ? numberFormat(social?.followers_count) : 0}</div>
                  </div>
                </div>
              </div> */}


              <h5 class="mb-20 basic-hd-top top-space">Profile</h5>


              <Form {...formProps}
                onFinish={saveBioData}>{formList[current]}
              </Form>

            </div>
          </div>
          <div className="profile_box_main col-md-6 mb-20">
            <div className="stream-box-white mb-20">
              <h3 className="mb-20 basic-hd-top">Shop URL</h3>
              {/* <h4 className='basic-hd2'>Instagram</h4> */}
              <div class="your-copy-link">
                <div class="item-a">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                    title={visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  >
                    {visitorURL + "/" + validate?.payload?.message?.pixel_id}
                  </a>
                </div>
                <div class="item-b">
                  <Button onClick={() => copyUrl(visitorURL + "/" + validate?.payload?.message?.pixel_id)}>
                    Copy
                  </Button>
                </div>
              </div>

              {/* <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Following</div>


                </div>
                <div className='followers-box-main'>
                  <div className='followers-box'>
                    
                    {
                      loading ? (
                        <Spin size="small" />
                      )
                        :
                        <div className='followers-count'>{instaData?.follows_count ? numberFormat(instaData?.follows_count) : 0}</div>
                    } </div>
                </div>
              </div>
              <div className='profile-hd-area'>
                <div className='followers-box-main'>
                  <div className='followers-txt'>Followers </div>


                </div>
                <div className='followers-box-main'>
                  <div className='followers-box'>
                    
                    {
                      loading ? (
                        <Spin size="small" />
                      )
                        :
                        <div className='followers-count'>{instaData?.followers_count ? numberFormat(instaData?.followers_count) : 0}</div>
                    }</div>
                </div>
              </div> */}


            </div>

            <div className="stream-box-white h-10 mb-0">
              <h5 className="mb-20 basic-hd-top">Change Password</h5>
              <Form name="basic" layout="vertical">
                <Form.Item
                  label="Enter Old Password"
                  name="currentPassword"
                  onChange={(e) => form.setFieldsValue({ currentPassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Current Password"
                  />
                </Form.Item>
                <Form.Item
                  label="Enter New Password"
                  name="newPassword"
                  onChange={(e) => form.setFieldsValue({ newPassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter New Password"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="changepassword"
                  onChange={(e) => form.setFieldsValue({ changepassword: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Confirm password"
                  />
                </Form.Item>
                <div className="mt-9">
                  <Button
                    onClick={handlePassword}
                    type="primary"
                    htmlType="submit"
                    className="default-btn ml-0 fw-normal"
                    loading={load}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
