import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  DatePicker,
  Spin,
  Empty,
  Checkbox,
  Select
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { bannedUsers } from "../../redux/actions/banUserList";
import { useStepsForm } from "sunflower-antd";
import {
  createDiscount,
  deleteDiscount,
  discountActivation,
  getDiscount,
  updateDiscount,
} from "../../redux/actions/discount.action";
import Swal from "sweetalert2";

const { RangePicker } = DatePicker;

export default () => {
  // const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalDiscount, setTotalDiscount] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const [banType, setBanType] = useState("chatban");
  const [groupByTable, setGroupByTable] = useState("chatban");
  const [discountModal, setDiscountModal] = useState(false);
  const [formState, setFormState] = useState("add");
  const [saveLoading, setSaveLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [tempTitle, setTempTitle] = useState();

  const { getDiscountList } = useSelector((state) => {
    return state;
  });
  const limit = 10;
  // const upperLimit = (currentPage + 1) * limit;
  // const lowerLimit = upperLimit - limit + 1;
  // const itemsPerPage = 15;
  // const pageCount = Math.ceil(totalDiscount / itemsPerPage);
  // const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  useEffect(() => {
    dispatch(getDiscount(1, limit)).then((res) => {
      setLoading(false);
    });
  }, []);
  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getDiscount(page + 1, limit)).then((res) => {
      setLoading(false);
      setTotalDiscount(res?.payload?.message?.total_records);
    });
  };

  const handleCancel = () => {
    setDiscountModal(false);
    form.resetFields();
    setFormState("add")
    form.setFieldsValue({
      name: "",
      discount: "",
      start_date: "",
      end_date: "",
      discounted_product_marked: false,
      id: "",
      discount_duration: ""
    });
  };

  const handleOk = () => {
    // setDiscountModal(false)
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const selectStatus = (value) => {
    setFilterStatus(value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setGroupByTable(banType)
    // setStatus(true);
    console.log(filterStatus);
    let active = filterStatus === "active" ? true : filterStatus === "deactive" ? false : "";
    dispatch(getDiscount(1, limit, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), active, tempTitle)).then((res) => {
      setLoading(false);
      // setInfluencer("");
      setTotalDiscount(res?.message?.total_records);
      setCurrentPage(0);
    });
  };
  const handleReset = (res) => {
    setLoading(true);
    // setInfluencerId();
    setFilterStatus("");
    // childRef.current?.clear();
    // setLoadd(true)
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment().endOf("year").format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    setTempTitle()
    dispatch(getDiscount(1, limit, min_date, max_date, "")).then((res) => {
      setLoading(false);
      // setInfluencer("");
      setTotalDiscount(res?.message?.total_records);
      setCurrentPage(0);
    });
  };
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      if (formState === "add") {
        let data = {
          name: values.name,
          discount: values.discount,
          start_date: moment(values.discount_duration[0]).format("YYYY-MM-DD"),
          end_date: moment(values.discount_duration[1]).format("YYYY-MM-DD"),
          discounted_product_marked: values.discounted_product_marked,
        };
        setSaveLoading(true);
        dispatch(createDiscount(data)).then((res) => {
          console.log(res, "res");
          if (res.type === "POST_DISCOUNT_SUCCESS") {
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            setDiscountModal(false);
            form.resetFields();
            setSaveLoading(false);
            setCurrentPage(0);
            // dispatch(validateUser(token));
            // }
            setLoading(true);
            dispatch(getDiscount(1, limit)).then((res) => {
              if (res.type == "GET_DISCOUNT_SUCCESS") {
                setLoading(false);
                // form.setFieldsValue({
                //   shopUrl: res?.payload?.message?.shopify?.shop_name,
                //   apiKey: res?.payload?.message?.shopify?.api_key,
                //   token: res?.payload?.message?.shopify?.password,
                //   storefrontKey:
                //     res?.payload?.message?.shopify?.storefront_access_token,
                // });
                // setFormState("edit");
                // handleClose();
              } else {
                if (res.type == "GET_DISCOUNT_ERROR") {
                  setLoading(false);
                }
              }
            });
          } else {
            notification.error({
              message: res?.payload?.message,
              className: "toast-error",
            });
          }
        });
      }
      else {
        let data = {
          name: values.name,
          discount: values.discount,
          start_date: moment(values.discount_duration[0]).format("YYYY-MM-DD"),
          end_date: moment(values.discount_duration[1]).format("YYYY-MM-DD"),
          discounted_product_marked: values.discounted_product_marked,
          id: values._id
        };
        setSaveLoading(true);
        dispatch(updateDiscount(data)).then((res) => {
          console.log(res, "res");
          if (res.type === "UPDATE_DISCOUNT_SUCCESS") {
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            setDiscountModal(false);
            form.resetFields();
            setSaveLoading(false);
            setLoading(true);
            setCurrentPage(0);
            form.setFieldsValue({
              name: "",
              discount: "",
              start_date: "",
              end_date: "",
              discounted_product_marked: false,
              id: "",
              discount_duration: ""
            });
            setFormState("add");
            dispatch(getDiscount(1, limit)).then((res) => {
              if (res.type == "GET_DISCOUNT_SUCCESS") {
                setLoading(false);
                // form.setFieldsValue({
                //   shopUrl: res?.payload?.message?.shopify?.shop_name,
                //   apiKey: res?.payload?.message?.shopify?.api_key,
                //   token: res?.payload?.message?.shopify?.password,
                //   storefrontKey:
                //     res?.payload?.message?.shopify?.storefront_access_token,
                // });
                // setFormState("edit");
                // handleClose();
              } else {
                if (res.type == "GET_DISCOUNT_ERROR") {
                  setLoading(false);
                }
              }
            });
          } else {
            notification.error({
              message: res?.payload?.message,
              className: "toast-error",
            });
          }
        });
      }
    },
  });

  function startDateLimit(current) {
    return current && current < moment().startOf("day");
  }

  const removeDiscount = (id) => {
    Swal.fire({
      title: `Are You Sure You Want To delete this discount?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        // setSpin(true);
        dispatch(deleteDiscount(id)).then((res) => {
          console.log(res, "resdelete");
          if (res?.type === "DELETE_DISCOUNT_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          } else {
            notification.success({
              message: res?.message,
              className: "toast-success",
            });
            setLoading(true);
            dispatch(getDiscount(1, limit)).then((res) => {
              setLoading(false);
            });
          }
          // setSpin(false);
        });
      }
    });
  };

  const editDiscount = (item) => {
    setDiscountModal(true);
    form.setFieldsValue({
      name: item?.name,
      discount: item?.discount,
      discounted_product_marked: item?.discounted_product_marked,
      discount_duration: [moment(item?.start_date, 'YYYY-MM-DD'), moment(item?.end_date, 'YYYY-MM-DD')],
      _id: item?._id
    });
    setFormState("edit");
  };

  const discountAction = (item) => {
    console.log(item, "item");
    Swal.fire({
      title: `Are You Sure You Want To ${item.active ? "DeActivate" : "Activate"} this discount?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        // setSpin(true);
        let data = {
          id: item._id,
          active: !item.active
        }
        dispatch(discountActivation(data)).then((res) => {
          console.log(res, "discountActivation");
          if (res?.type === "ACTIVATION_DISCOUNT_ERROR") {
            notification.error({
              message: res?.payload?.data?.message,
              className: "toast-error",
            });
          } else {
            notification.success({
              message: res?.message,
              className: "toast-success",
            });
            setLoading(true);
            dispatch(getDiscount(1, limit)).then((res) => {
              setLoading(false);
            });
          }
          // setSpin(false);
        });
      }
    });
  };

  function DefaultTable() {
    // let data = shopifyCustomerorder?.payload?.message?.data;
    if (getDiscountList?.payload?.message?.data) {
      return (
        <>
          <div className="table-responsive scrollbar-style">
            <table className="transactions-box table">
              <thead className="table_heading">
                <tr>
                  <th className="td-width-60">S.#</th>
                  <th className="td-width-160">Name</th>
                  <th className="td-width-160">Discount</th>
                  <th className="td-width-160">Start Date</th>
                  <th className="td-width-160">End Date</th>
                  <th className="td-width-160 text-center">Include MarkDowns</th>
                  <th className="td-width-200 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {getDiscountList?.payload?.message?.data?.length === 0 ? (
                  <tr>
                    <td colspan="12">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                ) : (
                  getDiscountList?.payload?.message?.data?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1 + currentPage * limit}</td>
                        <td>{item?.name}</td>
                        <td>{item?.discount ? (item?.discount + "%") : "-"}</td>
                        <td>{item?.start_date}</td>
                        <td>{item?.end_date}</td>
                        <td className="text-center">
                          {item?.discounted_product_marked ? "Yes" : "No"}
                        </td>
                        <td className="text-right">
                          {item?.active ?
                            <button
                              type="button"
                              onClick={() => {
                                discountAction(item);
                              }}
                              class="ant-btn green-btn-new green-width default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                            >
                              <span>
                                Activated
                              </span>
                            </button>
                            : <button
                              type="button"
                              onClick={() => {
                                discountAction(item);
                              }}
                              class="ant-btn red-btn-new default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                            >
                              <span>
                                De-Activated
                              </span>
                            </button>
                          }
                          <button
                            type="button"
                            onClick={() => {
                              editDiscount(item);
                            }}
                            class="ant-btn ant-btn-primary default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                          >
                            <span title="edit">
                              <i class="fa fa-pencil" />
                            </span>
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              removeDiscount(item?._id);
                            }}
                            class="ant-btn ant-btn-re red-btn-new default-btn padb-3 small-btn m-width-100 ml-0 mt-1 mb-1 fw-normal h-30"
                          >
                            <span title="remove">
                              <i class="fa fa-trash" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  }

  const validateInputNumber = (_, value) => {
    if (value > 100) {
      return Promise.reject(new Error('Discount must be less than or equal to 100'));
    }
    return Promise.resolve();
  };

  const formDiscount = [
    <div className="form-styling ">
      <Form.Item
        name="name"
        labelCol={{ span: 24 }}
        label="Enter Discount Name"
        initialValue={formState === "add" ? null : form.getFieldValue().name}
        rules={[
          {
            required: true,
            message: "Please Enter Discount Name",
          },
        ]}
      >
        <Input placeholder="Enter Discount Name" size="large" />
      </Form.Item>

      <Form.Item
        name="discount"
        labelCol={{ span: 24 }}
        label="Enter Discount"
        initialValue={formState === "add" ? "" : form.getFieldValue().discount}
        rules={[
          {
            required: true,
            message: "Please Enter Discount",
          },
          {
            validator: validateInputNumber,
          },
        ]}
      >
        <InputNumber
          className="w-100"
          size="large"
          // defaultValue={1}
          placeholder="Enter Discount"
          min={1}
          // max={100}
          formatter={(value) => `${value}`}
          parser={(value) => value.replace("", "")}
          addonAfter="%"
        />
      </Form.Item>

      <Form.Item
        name="discount_duration"
        labelCol={{ span: 24 }}
        label="Discount Duration"
        initialValue={
          formState === "add" ? null : form.getFieldValue().discount_duration
        }
        rules={[
          {
            required: true,
            message: "Please Enter Discount Duration",
          },
        ]}
      >
        <RangePicker
          className="w-100"
          size="large"
          disabledDate={startDateLimit}
        />
      </Form.Item>

      <Form.Item
        className="checkbox-flip"
        name="discounted_product_marked"
        labelCol={{ span: 24 }}
        // label="Discount Duration"
        valuePropName="checked"
        initialValue={
          formState === "add"
            ? false
            : form.getFieldValue().discounted_product_marked
        }
      // rules={[
      //   {
      //     required: true,
      //     message: "Please Enter Discount Duration",
      //   },
      // ]}
      >
        <Checkbox>Include MarkDowns</Checkbox>
      </Form.Item>

      <div className="d-flex justify-content-end">
        <Button
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
          disabled={saveLoading}
          loading={saveLoading}
        >
          <span className="nav-text">
            {formState === "add" ? "Create" : "Update"} Discount
          </span>
        </Button>
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  return (
    <>
      <div className="top-btn-area mb-10 d-inline-flex">
        <button
          onClick={() => setDiscountModal(true)}
          className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
        >
          <span className="nav-text ">Create New Discount</span>
        </button>
      </div>
      <div className="sales-page-main">
        <div className="grid-listing-area flex-column  flex-sm-column align-items-sm-start flex-md-column align-items-md-start flex-lg-column align-items-lg-start flex-xl-row align-items-start position-relative">
          <div className="grid-listing-left flex-grow-1">
            <div class="title-area mb-0 pb-1">
              <h1>Discount Management</h1>
            </div>
            <p className="note-txt fw-bold">Note: Only one discount can be activated at a time.</p>
          </div>

          <div className="grid-listing-right">
            {/* <button
              onClick={() => setDiscountModal(true)}
              className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
            >
              <span className="nav-text ">Create New Discount</span>
            </button> */}
          </div>
        </div>

        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Date Range</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],
                    // "Last Year": [
                    //   moment().startOf("year"),
                    //   moment().subtract(1, "year").endOf("year"),
                    // ],
                    // "This Year": [moment().startOf("year"),
                    // moment().endOf("year"),],
                    // "Last Month": [
                    //   moment().subtract(1, "month").startOf("month"),
                    //   moment().subtract(1, "month").endOf("month"),
                    // ],
                    // "This Month": [
                    //   moment().startOf("month"),
                    //   moment().endOf("month"),
                    // ],
                    // Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15"> */}
              {/* <p className="mb-5rem">Select Creator</p> */}
              {/* <div className="select-inf-field d-flex flow-row">
              <AsyncInfluencer
                ref={childRef}
                getInfluencer={getInfluencer}
                influencer={influencer}
              />
            </div> */}
              {/* <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Creator"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={influencerId}
                  onChange={(value) => selectInfluencer(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  options={getAllInfluencers?.message && [
                    ...getAllInfluencers?.message].map((influencer) => {
                      return ({
                        label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                        value: influencer?.user_id
                      }
                      )
                    })
                  }
                /> */}
              {/* </div> */}
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Name</p>
                <Input
                  className="w-100"
                  placeholder="Enter Name"
                  size="large"
                  type="text"
                  value={tempTitle}
                  onChange={(e) => setTempTitle(e?.target?.value)}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Status</p>
                {/* <div className="select-inf-field d-flex flow-row">
              <AsyncInfluencer
                ref={childRef}
                getInfluencer={getInfluencer}
                influencer={influencer}
              />
            </div> */}
                <Select
                  showSearch
                  name="sort"
                  size="large"
                  placeholder="Select Status"
                  className="w-100 select-style dark-field"
                  optionFilterProp="children"
                  value={filterStatus}
                  defaultValue=""
                  onChange={(value) => selectStatus(value)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  // options={getAllInfluencers?.message && [
                  //   ...getAllInfluencers?.message].map((influencer) => {
                  //     return ({
                  //       label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  //       value: influencer?.user_id
                  //     }
                  //     )
                  //   })
                  // }
                  options={[
                    { value: '', label: 'All' },
                    { value: 'active', label: 'Activated' },
                    { value: 'deactive', label: 'De-Activated' },
                  ]}
                />
              </div>





              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>



          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                {<DefaultTable />}
                <div className="purchase-data referral-data">
                  {getDiscountList?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(
                        getDiscountList?.payload?.message?.total / limit
                      )}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        className="modal-generic modal-500"
        centered
        open={discountModal}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      >
        <h2 className="modal-hd1">{formState === "add" ? "Create" : "Update"} Discount</h2>
        {!loading ? (
          <Form {...formProps}>{formDiscount}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>
    </>
  );
};
