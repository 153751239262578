import axios from 'axios';
import {
    GET_INVENTORY_ACTIVE, INVENTORY_SELLOUT, INVENTORY_DISABLE, GET_INVENTORY_DISABLED, GET_INVENTORY_SOLDOUT, INVENTORY_REPLACE, INVENTORY_SEARCH_SKU, GET_INVENTORY_REPLACED, INVENTORY_ENABLE, PRODUCT_SEARCH_SKU, ALL_PRODUCT_SEARCH_SKU,
    ALL_PRODUCT_SEARCH_SKU_AND_TITLE, ADD_TO_ORME_REQUEST, ADD_TO_ORME_SUCCESS, ADD_TO_ORME_ERROR, INVENTORY_SEARCH_DESC,
    GET_LINKED_VIDEOS
} from '../types/types';

export const getInventory = (limit, page, type, startData, endData, gender, category, subCategory, sku, order_by, counter_type, id, sort, title, title_sku, description, description_sku) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/inventory`,
        params: {
            limit,
            page
        },
        data: {
            type,
            from_date: startData,
            to_date: endData,
            gen_category: gender !== "All" ? gender : "all",
            category: category !== "All" ? category : "all",
            sub_category: subCategory !== "All" ? subCategory : "all",
            sku: sku ? sku : "",
            order_by: order_by ? order_by : "inventory",
            counter_type: counter_type ? counter_type : "",
            id,
            sort: sort ? sort : "desc",
            title: title_sku ? undefined : title,
            title_sku: title_sku ? title_sku : undefined,
            description: description_sku ? undefined : description,
            description_sku: description_sku ? description_sku : undefined,
        }
    })
        .then((response) => {
            switch (type) {
                case "active":
                    dispatch({
                        type: GET_INVENTORY_ACTIVE,
                        payload: response.data,
                    });
                    return response.data;
                case "disable":
                    dispatch({
                        type: GET_INVENTORY_DISABLED,
                        payload: response.data,
                    });
                    return response.data
                case "soldout":
                    dispatch({
                        type: GET_INVENTORY_SOLDOUT,
                        payload: response.data,
                    });
                    return response.data
                case "replaced":
                    dispatch({
                        type: GET_INVENTORY_REPLACED,
                        payload: response.data,
                    });
                    return response.data
                default:
                    return null;
            }

        })
        .catch((error) => {
            return error
        });
};

export const sellOutSku = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/marksoldout`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_SELLOUT,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const disableSku = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/markdisabled`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_DISABLE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const enableSku = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/markenabled`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_ENABLE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const replaceSkus = (Id, products) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/replaced`,
        params: {
            Id
        },
        data: {
            products
        }
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_REPLACE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchActiveSkus = (type, startData, endData, gender, category, subCategory, sku) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/searchsku`,
        data: {
            type,
            from_date: startData,
            to_date: endData,
            gen_category: gender !== "All" ? gender : "all",
            category: category !== "All" ? category : "all",
            sub_category: subCategory !== "All" ? subCategory : "all",
            sku: sku ? sku : ""
        }
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_SEARCH_SKU,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchSku = (page, limit, sku, title) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `ecom/searchsku`,
        params: {
            page,
            limit
        },
        data: {
            sku: sku,
            title: title
        }
    })
        .then((response) => {
            dispatch({
                type: PRODUCT_SEARCH_SKU,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchAllSkus = (data) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `ecom/getsku`,
        data: {
            ...data
        }
    })
        .then((response) => {
            dispatch({
                type: ALL_PRODUCT_SEARCH_SKU,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};


export const searchBySkuAndTitle = (data, limit, page) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `ecom/searchBySkuAndTitle`,
        params: {
            page: page ? page : 1,
            limit
        },
        data: {
            ...data,
            // sort_on: "price",
            // sort: "desc"
        }
    })
        .then((response) => {
            dispatch({
                type: ALL_PRODUCT_SEARCH_SKU_AND_TITLE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};


export const addToOrme = (data) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `ecom/activedeactiveproducts`,

        data: data
    })
        .then((response) => {
            dispatch({
                type: ADD_TO_ORME_SUCCESS,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};


export const searchDesc = (type, title, productDesc, startData, endData, gender, category, subCategory) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/searchByDesc`,
        data: {
            productDesc,
            title,
            type,
            from_date: startData,
            to_date: endData,
            gen_category: gender !== "All" ? gender : "all",
            category: category !== "All" ? category : "all",
            sub_category: subCategory !== "All" ? subCategory : "all",
        }
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_SEARCH_DESC,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const getLinkedVideos = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/linkedvideos`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: GET_LINKED_VIDEOS,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};