import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form, Input } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import {
  referralStatsReport,
  referralSummary,
} from "../../redux/actions/referralStatsReport.action";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../../css/sales.scss";
import moment from "moment";
import numeral from "numeral";
import { getSavedCategories, getSavedGeneralCategories, getSavedSubCategories } from "../../redux/actions/newCategory.action";
import { getPurchaser, getReferrer } from "../../redux/actions/purchaseHistory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
const { Option } = Select;

const { RangePicker } = DatePicker;

export default () => {
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");
  const [getEvent, setGetEvent] = useState([]);
  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [source, setSource] = useState("all");
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState([]);
  const [submit, setSubmit] = useState("none");
  const [fee, setfee] = useState("");
  const [load, setLoad] = useState(false);
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [orderNo, setOrderNo] = useState();
  const [purchaseBy, setPurchaseBy] = useState();
  const [referredBy, setReferredBy] = useState();
  const [searchPurchaseBy, setSearchPurchaseBy] = useState([]);
  const [searchReferredBy, setsearchReferredBy] = useState([]);
  const [purchaseLoad, setPurchaseLoad] = useState(false);
  const [referrerLoad, setReferrerLoad] = useState(false);
  const [groupBy, setGroupBy] = useState("Order");
  const [groupByTable, setGroupByTable] = useState("Order");
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");
  const {
    shopifyCustomerorder,
    referralStats,
    summaryReferral,
    savedGeneralCategories, savedCategory, savedSubCategory
  } = useSelector((state) => {
    return state;
  });
  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;
  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalEvent / itemsPerPage);
  const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

  useEffect(() => {
    setSearchIDLoad(true)
    dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
      setSearchIDLoad(false)
      if (res?.success) {
        setSearchDataIDs(res?.message)
      }
    })
    setGeneralLoad(true)
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGeneralLoad(false)
      } else {
        setGeneralLoad(false)
      }
    })

    setLoading(true);
    // dispatch(referralStatsReport(userInfo?.token, startDate, moment(endDate).add(1, "days").format("YYYY-MM-DD"))).then((res) => {
    dispatch(
      referralStatsReport(1, limit, startDate, endDate, gender, category, subCategory, source, "", "", "", "Order")
    ).then((res) => {
      setGetEvent(res?.message?.data);
      setTotalEvent(res?.message?.total_records);
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(
      referralStatsReport(page + 1, limit, moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"), gender, category, subCategory, source, orderNo,
        referredBy,
        purchaseBy, groupBy, searchID,
        idType)
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.message?.data);
      setTotalEvent(res?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setGroupByTable(groupBy)
    setLoading(true);
    setCurrentPage(0);
    dispatch(
      referralStatsReport(
        1,
        limit,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        gender, category, subCategory, source, orderNo,
        purchaseBy,
        referredBy, groupBy, searchID,
        idType)
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.message?.data);
      setTotalEvent(res?.message?.total_records);
    });
    // setSubmit(groupBy);
  };

  const getSlicedData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return referralStats?.payload?.message?.data.slice(startIndex, endIndex);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSource = (e) => {
    setSource(e);
  };

  const handleFilterPurchaseBy = (e) => {
    console.log("e", e);
    setPurchaseLoad(true)
    dispatch(getPurchaser(e)).then((res) => {
      console.log("purchaser res", res);
      setPurchaseLoad(false)
      if (res?.success) {
        setSearchPurchaseBy(res?.message)
      }
    })

  }

  const handleFilterReferredBy = (e) => {
    setReferrerLoad(true)
    dispatch(getReferrer(e)).then((res) => {
      setReferrerLoad(false)
      if (res?.success) {
        setsearchReferredBy(res?.message)
      }
    })

  }

  const handleGender = (e) => {
    setCurrentPage(0);
    setGender(e);
    setCategory(null)
    setSubCategory(null)
    setCatLoad(true)
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false)
      } else {
        setCatLoad(false)
      }
    })

  };

  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {

            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleCategory = (e) => {
    setCurrentPage(0);
    setCategory(e);
    setSubCategory(null)
    setSubCatLoad(true)
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false)
      } else {
        setSubCatLoad(false)
      }
    })
  };

  const handleSubCategory = (e) => {
    setCurrentPage(0);
    setSubCategory(e);
  };

  const handleReset = (res) => {
    setIdType("event")
    setSearchID()
    setSource("all");
    setGroupByTable("Order")
    setGroupBy("Order");
    setSubmit("none");
    setLoading(true);
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setCurrentPage(0);
    setOrderNo()
    setPurchaseBy()
    setReferredBy()
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);
    // dispatch(referralStatsReport(userInfo?.token, moment().startOf("month").format("YYYY-MM-DD"), moment(new Date()).add(1, "days").format("YYYY-MM-DD"), "all", "none")).then((res) => {
    dispatch(
      referralStatsReport(
        1,
        limit,
        moment().startOf("year").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
        "",
        "",
        "",
        "all",
        "",
        "",
        "",
        "Order"
      )
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.message?.data);
      setTotalEvent(res?.message?.total_records);
    });
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  function Summary() {
    return (
      referralStats?.payload?.message?.summary.length > 0 && (
        <div className="sales-summery mb-3">
          <div className="row">
            <div className="col-lg-6 col-xl-4 mb-2">
              {referralStats?.payload?.message?.summary.map((item, i) => {
                return (
                  <>
                    <div className="summarry-box new-style top-space">
                      <h4 className="summary-hd">Summary</h4>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Orders</h4>
                        <h3 className="count">{item?.order_count}</h3>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Amount</h4>
                        <h3 className="count">
                          {numeral(item?.order_totalprice).format("$0,0.0'")}
                        </h3>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Return Amount</h4>
                        <h3 className="count">
                          {numeral(item?.returned_amount).format("$0,0.0'")}
                        </h3>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Balance Amount</h4>
                        <h3 className="count">
                          {numeral(item?.balance_amount).format("$0,0.0'")}
                        </h3>
                      </div>
                      {/* <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Fee%</h4>
                        <h3 className="count">
                          {" "}
                          {numeral(
                            (item?.total_commission
                              ? item?.total_commission
                              : 0) / item?.order_totalprice
                          ).format("0.00%")}
                        </h3>
                      </div> */}
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total ORME Fee</h4>
                        <h3 className="count">
                          {numeral(item?.kb_fee ? item?.kb_fee : 0).format(
                            "$0,0.0'"
                          )}
                        </h3>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Cashback</h4>
                        <h3 className="count">
                          {numeral(
                            item?.cashback_amount
                              ? item?.cashback_amount
                              : 0
                          ).format("$0,0.0'")}
                        </h3>
                      </div>
                      <div className="col-12 summary-detail-box">
                        <h4 className="count-title">Total Referral Fee</h4>
                        <h3 className="count">
                          {numeral(
                            item?.customer_commission
                              ? item?.customer_commission
                              : 0
                          ).format("$0,0.0'")}
                        </h3>
                      </div>

                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  }

  function DefaultTable() {
    let data = referralStats?.payload?.message?.data;
    if (data) {
      switch (groupByTable) {
        case "Order":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Order Date</th>
                      <th className="td-width-150">Order#</th>
                      <th className="td-width-150">Department</th>
                      <th className="td-width-150">Category</th>
                      <th className="td-width-150">Sub Category</th>
                      <th className="td-width-150">Source</th>
                      <th className="td-width-150 ">Purchaser</th>
                      <th className="td-width-150 text-right">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 ">Referred By</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td>
                              {moment(item?.created_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <a
                                className="open-modal blue-link"
                                onClick={() => {
                                  setModal(true);
                                  setLoad(true);
                                  setOrder(item);
                                  dispatch(
                                    referralSummary(
                                      item?.event === "productshare"
                                        ? item?._id
                                        : item?.sales_tracker_id,
                                      item?.event
                                    )
                                  ).then((res) => {
                                    setLoad(false);
                                  });
                                  setfee(item?.total_commission);
                                }}
                              >
                                {item?.order_id}
                              </a>
                            </td>
                            <td>
                              {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                            </td>
                            <td>
                              {item?.category?.category_name ? item?.category?.category_name : "All"}
                            </td>
                            <td>
                              {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                            </td>

                            <td>
                              {item?.event === "productshare"
                                ? "Product Share"
                                : item?.event === "reviewshare"
                                  ? "Review Share"
                                  : "Event Share"}
                            </td>

                            <td className="">{item?.customer?.name}</td>
                            <td className="text-right">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="">{item?.affiliate?.name}</td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}

                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data?.length +
                              currentPage * 15}
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>


                        <td></td>
                        <td></td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td></td>

                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "date":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Order Date</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td>
                              {moment(item?.created_date).format("DD-MM-YYYY")}
                            </td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>
                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "genCategory":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Department</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}

                            <td>
                              {item?.gen_category?.name ? item?.gen_category?.name : "-"}
                            </td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>

                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "category":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Category</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}

                            <td>
                              {item?.category?.category_name ? item?.category?.category_name : "All"}
                            </td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>

                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "subCategory":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Sub Category</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td>
                              {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "-"}
                            </td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>

                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "source":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Source</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td>
                              {item?.event === "reviewshop"
                                ? "Review Share"
                                : item?.event === "reviewshare"
                                  ? "Review Share"
                                  : "Event Share"}
                            </td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>

                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        case "referrer":
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Referrer</th>
                      <th className="td-width-150">Total Orders</th>
                      <th className="td-width-150">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td className="">{item?.affiliate?.name}</td>
                            <td>

                              {item?.order_count ? item?.order_count : "-"}
                            </td>

                            <td className="">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data.reduce((a, b) => {
                              return (
                                Number(a) +
                                Number(
                                  b.order_count ? b.order_count : 0.0
                                )
                              );
                            }, 0)
                            }
                          </b>
                        </td>


                        <td className="total-sales">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Order Date</th>
                      <th className="td-width-150">Order#</th>
                      <th className="td-width-150">Department</th>
                      <th className="td-width-150">Category</th>
                      <th className="td-width-150">Sub Category</th>
                      <th className="td-width-150">Source</th>
                      <th className="td-width-150 ">Purchaser</th>
                      <th className="td-width-150 text-right">Amount</th>
                      <th className="td-width-150 text-right">Return Amount</th>
                      <th className="td-width-150 text-right">Balance Amount</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 ">Referred By</th>
                      <th className="td-width-150 text-right">Referral Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralStats.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : referralStats?.payload?.message?.data?.length === 0 ? (
                      <tr>
                        <td colspan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            {/* <td>{moment.utc(item?.created_at).format("DD-MM-YYYY")}</td> */}
                            <td>
                              {moment(item?.created_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <a
                                className="open-modal blue-link"
                                onClick={() => {
                                  setModal(true);
                                  setLoad(true);
                                  setOrder(item);
                                  dispatch(
                                    referralSummary(
                                      item?.event === "productshare"
                                        ? item?._id
                                        : item?.sales_tracker_id,
                                      item?.event
                                    )
                                  ).then((res) => {
                                    setLoad(false);
                                  });
                                  setfee(item?.total_commission);
                                }}
                              >
                                {item?.order_id}
                              </a>
                            </td>
                            <td>
                              {item?.gen_category?.name ? item?.gen_category?.name : "All"}
                            </td>
                            <td>
                              {item?.category?.category_name ? item?.category?.category_name : "All"}
                            </td>
                            <td>
                              {item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}
                            </td>

                            <td>
                              {item?.event === "productshare"
                                ? "Product Share"
                                : item?.event === "reviewshare"
                                  ? "Review Share"
                                  : "Event Share"}
                            </td>

                            <td className="">{item?.customer?.name}</td>
                            <td className="text-right">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.returned_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.balance_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="">{item?.affiliate?.name}</td>

                            {/* <td className="text-right">
                          {item?.event === "productshare" || item?.event === "productshare"
                            ? item?.fee_review ? item?.fee_review + "%" : 0 + "%"
                            : item?.fee_event ? item?.fee_event + "%" : 0 + "%"}
                        </td> */}

                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {referralStats?.payload?.message?.data?.length ? (
                      <tr>
                        <td></td>
                        <td className="total-sales">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {referralStats?.payload?.message?.data?.length +
                              currentPage * 15}
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>


                        <td></td>
                        <td></td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.returned_amount ? b.returned_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.balance_amount ? b.balance_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td></td>

                        <td className="total-sales text-right">
                          <b>
                            {numeral(
                              getEvent?.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>

                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
      }
    }
  }

  return (
    <>
      <div className="sales-page-main">
        {/* <div class="title-area mb-10">
          <h1>Referral Sales</h1>
        </div> */}
        <div className="brand_container_main aff-payment">
          <Form form={form}>
            <div className="row w-20-desktop-main">
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Start Date / End Date</p>
                <RangePicker
                  size="large"
                  className="w-100"
                  key={4}
                  value={
                    startDate && endDate
                      ? [moment(startDate), moment(endDate)]
                      : []
                  }
                  allowClear={false}
                  ranges={{
                    "All Time": [
                      moment().startOf("year"),
                      moment(),
                    ],
                    "YTD": [moment().startOf("year"), moment()],
                    "Last 30 Days": [moment().subtract(30, "days"), moment()],
                    "Last 7 Days": [moment().subtract(7, "days"), moment()],
                    "Today": [moment(), moment()],
                    // "Last Year": [
                    //   moment().startOf("year"),
                    //   moment().subtract(1, "year").endOf("year"),
                    // ],
                    // "This Year": [moment().startOf("year"), moment()],
                    // "Last Month": [
                    //   moment().subtract(1, "month").startOf("month"),
                    //   moment().subtract(1, "month").endOf("month"),
                    // ],
                    // "This Month": [
                    //   moment().startOf("month"),
                    //   moment().endOf("month"),
                    // ],
                    // Today: [moment(), moment()],
                  }}
                  format={dateFormat}
                  onChange={dateRangePickerChanger}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Search ID</p>
                <div className='d-flex align-items-center'>
                  <Select
                    placeholder="Type"
                    size="large"
                    className='idTypeSelect'
                    style={{
                      width: '35%',
                    }}
                    focus={false}
                    loading={loading}
                    onBlur={false}
                    value={idType}
                    onChange={(e) => handleIDType(e)}
                  >
                    <Option value="event">Event</Option>
                    <Option value="show">Show</Option>
                    <Option value="review">Review</Option>
                  </Select>
                  <Select
                    showSearch
                    size="large"
                    className="w-100"
                    key={4}
                    disabled={idType ? false : true}
                    notFoundContent={searchIDLoad && <Spin size="small" />}
                    loading={searchIDLoad}
                    optionFilterProp="children"
                    placeholder="Enter ID"
                    labelInValue
                    onChange={(e) => setSearchID(e)}
                    optionLabelProp="label"
                    onSearch={handleReviewID}
                    filterOption={(input, options) =>
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    focus={false}
                    value={searchID ? searchID?.label?.[0] + "-" + searchID?.label?.split(idType === "event" ? 'E' : (idType === "review" ? "R" : "S"))[1]?.split('-').join('')?.match(/.{1,3}/g)?.join('-') : null}
                    onBlur={false}
                  >
                    {searchDataIDs && !searchIDLoad &&
                      searchDataIDs?.map((item, i) => {
                        return (
                          <Option key={item?._id ? item?._id : (item?._id ? item?._id : item?._id)} label={item?.event_counter ? item?.event_counter : (item?.review_counter ? item?.review_counter : item?.show_counter)} >
                            {
                              idType === "event" ? item?.event_counter && item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                (idType === "review" ? item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                                  item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-'))
                            }
                          </Option>
                        );
                      })}
                  </Select>
                </div>

              </div>
              {/* <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Brand</p>
                <Select className="select-style dark-field w-100" placeholder="Brand" size="large" focus={false} onBlur={false} value="longaberger">
                  <Option value="longaberger">ORME</Option>
                </Select>
              </div> */}
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Department</p>
                <Select
                  size="large"
                  className="w-100"
                  key={4}
                  placeholder="Select Department"
                  focus={false}
                  onChange={handleGender}
                  value={
                    gender
                  }
                  onBlur={false}
                  loading={generalLoad}
                  disabled={!savedGeneralCategories || generalLoad}
                >

                  {savedGeneralCategories &&
                    savedGeneralCategories?.message?.map((item, i) => {
                      return (
                        <Option value={item?.category_id}>
                          {item?.name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Category</p>
                <Select
                  size="large"
                  className="w-100"
                  key={4}
                  placeholder="Select Category"
                  focus={false}
                  onChange={handleCategory}
                  value={
                    category
                  }
                  onBlur={false}
                  loading={catLoad}
                  disabled={(!savedCategory.success || catLoad) || !gender}
                >
                  <Option value="all">
                    {"All"}
                  </Option>
                  {savedCategory &&
                    savedCategory?.message?.map((item, i) => {
                      return (
                        <Option value={item?.category_id}>
                          {item?.category_name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Sub Category</p>
                <Select
                  size="large"
                  className="w-100"
                  key={4}
                  placeholder="Select Sub Category"
                  focus={false}
                  onChange={handleSubCategory}
                  value={
                    subCategory
                  }
                  onBlur={false}
                  loading={subCatLoad}
                  disabled={(!savedSubCategory?.success || subCatLoad) || (!gender || !category)}
                >
                  <Option value="">
                    {"All"}
                  </Option>
                  {savedSubCategory &&
                    savedSubCategory?.message?.map((item, i) => {
                      return (
                        <Option value={item?.sub_category_id}>
                          {item?.sub_category_name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Select Source</p>
                <Select
                  className="select-style dark-field w-100"
                  placeholder="Source"
                  size="large"
                  focus={false}
                  loading={loading}
                  disabled={loading}
                  onBlur={false}
                  onChange={handleSource}
                  value={source === "" ? null : source}
                >
                  <Option value="all">All</Option>
                  {/* <Option value="productshare">Product Share</Option> */}
                  <Option value="eventshare">Event Share</Option>
                  <Option value="reviewshare">Review Share</Option>
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Order</p>
                <Input
                  id="reportOrderInput"
                  type="Number"
                  className="w-100 select-modal"
                  defaultValue={""}
                  loading={loading}
                  disabled={loading}
                  placeholder="Enter Order No"
                  size="large"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                />
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Purchaser</p>
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  key={4}
                  notFoundContent={purchaseLoad && <Spin size="small" />}
                  loading={purchaseLoad}
                  optionFilterProp="children"
                  placeholder="Enter Customer Name"
                  onChange={(e) => setPurchaseBy(e)}
                  onSearch={handleFilterPurchaseBy}
                  filterOption={(input, options) =>
                    options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  focus={false}
                  // onChange={handleFilterSKU}
                  value={purchaseBy}
                  onBlur={false}
                >
                  {searchPurchaseBy && !purchaseLoad &&
                    searchPurchaseBy?.map((item, i) => {
                      return (
                        <Option value={item?._id}>
                          {item?.name + " (" + item?.email + ")"}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Referrer</p>
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  key={4}
                  notFoundContent={referrerLoad && <Spin size="small" />}
                  loading={referrerLoad}
                  optionFilterProp="children"
                  placeholder="Enter Referrer"
                  onChange={(e) => setReferredBy(e)}
                  onSearch={handleFilterReferredBy}
                  filterOption={(input, options) =>
                    options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  focus={false}
                  value={referredBy}
                  onBlur={false}
                >
                  {searchReferredBy && !referrerLoad &&
                    searchReferredBy?.map((item, i) => {
                      return (
                        <Option value={item?._id}>
                          {item?.name + " (" + item?.email + ")"}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-xl-3 col-md-6 col-12 mb-15">
                <p className="mb-5rem">Group By</p>
                <Select
                  className="w-100 select-style dark-field"
                  placeholder="Select Group By"
                  size="large"
                  focus={false}
                  onBlur={false}
                  loading={loading}
                  disabled={loading}
                  value={groupBy}
                  onChange={(e) => setGroupBy(e)}
                >
                  <Option value="">Order</Option>
                  <Option value="date">Date</Option>
                  <Option value="genCategory">Department</Option>
                  <Option value="category">Category</Option>
                  <Option value="subCategory">Sub Category</Option>
                  <Option value="source">Source</Option>
                  <Option value="referrer">Referrer</Option>
                  <Option disabled value="video_id">Video ID</Option>
                </Select>
              </div>
              {/* //asda */}
              <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                <Button
                  onClick={handleSubmit}
                  className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
                <Button
                  onClick={handleReset}
                  className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
          <hr className="separator-line mt-0" />
          <div className="purchase-data referral-data">
            {loading ? (
              <div className="loading-wrap">
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              </div>
            ) : (
              <>
                <div className="purchase-data referral-data">
                  {DefaultTable()}
                  {referralStats?.payload?.message?.data?.length ? (
                    <ReactPaginate
                      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                      pageCount={Math.ceil(totalEvent / limit)}
                      forcePage={currentPage}
                      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                      pageClassName="page-item d-none"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item d-none"
                      breakLinkClassName="page-link"
                      containerClassName={"pagination custom-paginate"}
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  ) : (
                    ""
                  )}
                  <Summary />
                  <br></br>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="code-activated-popup"
        size="lg"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          ) : (
            <>
              <div className="order-detail-title-area">
                <span className="order-num">
                  Order # <div className="order-name">{order?.order_id}</div>
                </span>

                <span className="order-date">
                  {moment(order?.created_date).format(
                    "MMMM Do YYYY [from Online Store]"
                  )}
                </span>
              </div>
              <Table responsive size="sm" className="transactions-box">
                <thead className="table_heading">
                  <tr>
                    <th>S.#</th>
                    <th>Order Date</th>
                    <th>SKU #</th>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryReferral.loading ? (
                    <>
                      <tr>
                        <td colspan={6}>
                          <Spin
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {summaryReferral?.payload?.message?.order_detail?.line_items?.map(
                        (item, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>
                                {moment(order?.created_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{item?.sku}</td>
                              <td>{item?.title}</td>
                              <td>{item?.quantity}</td>
                              <td>{numeral(item?.price).format("$0,0.0'")}</td>
                              <td>
                                {numeral(
                                  getSum(item?.quantity, item?.price)
                                ).format("$0,0.0'")}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </>
                  )}
                </tbody>
              </Table>
              <div className="order-sum">
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Purchased By</div>
                  </div>
                  <div className="right-txt">
                    {order?.event === "productshare"
                      ? "Anonymous"
                      : summaryReferral?.payload?.message?.customer?.name}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Subtotal</div>
                    <div className="left-info">
                      {
                        summaryReferral?.payload?.message?.order_detail
                          ?.line_items.length
                      }{" "}
                      item
                    </div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      summaryReferral?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Discount</div>
                    {summaryReferral?.payload?.message?.order_detail
                      ?.discount_codes?.[0]
                      ? numeral(
                        summaryReferral?.payload?.message?.order_detail
                          ?.discount_codes?.[0]?.amount /
                        summaryReferral?.payload?.message?.order_detail
                          ?.total_line_items_price
                      ).format("0%")
                      : ""}
                  </div>
                  {summaryReferral?.payload?.message?.order_detail
                    ?.discount_codes?.[0]?.amount ? (
                    <>
                      <div className="right-txt">
                        -
                        {numeral(
                          summaryReferral?.payload?.message?.order_detail
                            ?.discount_codes?.[0]?.amount
                        ).format("$0,0.0'")}
                      </div>
                    </>
                  ) : (
                    <>$0.00</>
                  )}
                </div>

                {/* <div className="paid-mid-row">
              <div className="left-txt multi-content">
                <div className="left-hd">Gross Amount</div>
              </div>
              {summaryReferral?.payload?.message?.order_detail?.discount_codes?.length === 0 ? <div className="right-txt">{numeral(summaryReferral?.payload?.message?.order_detail?.total_line_items_price).format("$0,0.0'")}</div> : <div className="right-txt">{numeral(getGross(summaryReferral?.payload?.message?.order_detail?.total_line_items_price, summaryReferral?.payload?.message?.order_detail?.discount_codes?.[0]?.amount)).format("$0,0.0'")}</div>}
            </div> */}

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Shipping</div>
                    {/* <div className="left-info">{summaryReferral?.payload?.message?.order_detail?.line_items.length} item</div> */}
                  </div>
                  <div className="right-txt">
                    {numeral(
                      summaryReferral?.payload?.message?.order_detail
                        ?.total_shipping_price_set?.presentment_money?.amount
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Tax</div>
                  </div>
                  <div className="right-txt">
                    {numeral(
                      summaryReferral?.payload?.message?.order_detail?.total_tax
                    ).format("$0,0.0'")}
                  </div>
                </div>
                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd fw-bold">Total Amount</div>
                  </div>
                  <div className="right-txt fw-bold">
                    {numeral(
                      summaryReferral?.payload?.message?.order_detail
                        ?.total_price
                    ).format("$0,0.0'")}
                  </div>
                </div>

                <div className="paid-mid-row">
                  <div className="left-txt multi-content">
                    <div className="left-hd">Commission</div>
                    {/* {summaryReferral?.payload?.message?.event_payload?.referral_percent ? <div className="left-info">{summaryReferral?.payload?.message?.event_payload?.referral_percent}%</div> : <>0%</> } */}
                  </div>
                  <div className="right-txt">
                    {numeral(((summaryReferral?.payload?.message?.order_detail
                      ?.total_line_items_price - (summaryReferral?.payload?.message?.order_detail
                        ?.discount_codes?.[0]?.amount ? summaryReferral?.payload?.message?.order_detail
                          ?.discount_codes?.[0]?.amount : 0)) * (summaryReferral?.payload?.message?.event_payload
                            ?.referral_percent ? summaryReferral?.payload?.message?.event_payload
                            ?.referral_percent : summaryReferral?.payload?.message?.review_payload
                            ?.referral_percent)) / 100).format("$0,0.0'")}
                  </div>
                </div>
              </div>
              {summaryReferral?.payload?.message?.order_return_detail &&
                <>
                  <div className="order-detail-title-area">
                    <span className="order-num">
                      Refund Detail <div className="order-name">{order?.order_id}</div>
                    </span>
                  </div>
                  <Table responsive size="sm" className="transactions-box">
                    <thead className="table_heading">
                      <tr>
                        <th>S.#</th>
                        <th>Order Date</th>
                        <th>SKU #</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!summaryReferral?.loading &&
                        summaryReferral?.payload?.message?.order_return_detail?.refund_line_items?.map(
                          (item, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  {moment
                                    .utc(order?.created_at)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>{item?.line_item?.sku}</td>
                                <td>{item?.line_item?.title}</td>
                                {/* <td>{item?.line_item?.quantity}</td>
                              <td>
                                {numeral(
                                  getSum(item?.subtotal, item?.quantity)
                                ).format("$0,0.0'")}
                              </td> */}
                                <td>{item?.quantity}</td>
                                <td>
                                  {numeral(
                                    item?.line_item?.price
                                  ).format("$0,0.0'")}
                                </td>
                                <td>
                                  {numeral(item?.subtotal).format("$0,0.0'")}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </Table>
                  <div className="order-sum">
                    <>
                      <div className="paid-mid-row">
                        <div className="left-txt multi-content">
                          <div className="left-hd">Subtotal</div>
                          <div className="left-info">
                            {summaryReferral?.payload?.message
                              ?.order_return_detail?.refund_line_items?.length
                              ? summaryReferral?.payload?.message
                                ?.order_return_detail?.refund_line_items?.length
                              : 0}{" "}
                            item
                          </div>
                        </div>
                        <div className="right-txt">
                          {numeral(
                            summaryReferral?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                              (acc, item) => {
                                return (acc = acc + item.subtotal);
                              },
                              0
                            )
                          ).format("$0,0.0'")}
                        </div>
                      </div>
                      <div className="paid-mid-row">
                        <div className="left-txt multi-content">
                          <div className="left-hd">Tax</div>
                        </div>
                        <div className="right-txt">
                          {numeral(
                            summaryReferral?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                              (acc, item) => {
                                return (acc = acc + item.total_tax);
                              },
                              0
                            )
                          ).format("$0,0.0'")}
                        </div>
                      </div>
                      <div className="paid-mid-row">
                        <div className="left-txt multi-content">
                          <div className="left-hd fw-bold">Total Amount</div>
                        </div>
                        <div className="right-txt fw-bold">
                          {numeral(
                            summaryReferral?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                              (acc, item) => {
                                return (acc = acc + item.subtotal);
                              },
                              0
                            ) +
                            summaryReferral?.payload?.message?.order_return_detail?.refund_line_items?.reduce(
                              (acc, item) => {
                                return (acc = acc + item.total_tax);
                              },
                              0
                            )
                          ).format("$0,0.0'")}
                        </div>
                      </div>
                    </>
                  </div>
                </>
              }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModal(false)}
            className="default-btn outline padb-3"
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
            Back
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};
