import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
// import { toast } from "react-toastify";
import '../../../css/asyncProduct.scss';
import { BASEURL } from '../../../config';
import { notification } from 'antd';

let arrsku = [];
class AsyncProduct extends React.Component {


  state = {
    skuData: this.props.SkuData,
    sku: this.props.sku,
    // replaceSku: this.props.replaceSku,
    allSku: '',
    referral_fee: this.props.referralPercent,
    influencer_fee: this.props.influencer_percent,
    replace: this.props.replace,
    skuArr: this.props.skuArr
    // default_value: this.props.defaultValue,
  };

  componentDidUpdate() {

    // if (this.props.remove) {
    //   let temp = this.state.sku;
    //   let tempp = temp.pop();
    //   this.setState({
    //     sku: (this.state.sku.pop())
    //   });
    //   this.props.setRemove(false)
    // }
  }

  loadOptions = async (input, callback) => {

    await this.smartSearchFilter(input);

    const result = arrsku.map((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
        price: `${item.price}`,
        title: `${item.title}`,
        referral_percent: this.props.referralPercent,
        influencer_percent: this.props.influencer_percent,
        id: item?._id,
        ProductName: item?.title,
        ProductSku: item?.ProductSku,
        ProductUrl: item.ProductUrl,
        mediaUrl: item?.mediaUrl,
        productAmount: item?.productAmount,
        productDesc: item.productDesc,
        productOpt: item?.productOpt,
        variants: item?.variants,
        is_active: true,
        tags: item?.tags
      };
    });
    callback(result);
  };
  smartSearchFilter = async (value) => {

    if (value.length > 2 && value.trim()) {
      await axios
        .post(
          `ecom/searchBySkuAndTitle`,
          {
            sku: value.trim(),
            is_orme_product: true
          },
        )
        .then((response) => {
          const loadSku = [];
          const sku = response.data.message;
          if (sku.length === 0) {
            notification.error({
              message: 'No Product Found',
              className: 'toast-error',
            });
          }

          this.setState({ allSku: sku });
          arrsku = sku.map(({ _source }) => {
            const productUrl =
              'https://' + _source?.domain + '/products/' + _source?.handle;
            let description = _source?.body_html
              ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "")
              : "";

            return ({
              value: _source?.variants[0]?.sku,
              label: _source?.variants[0]?.sku,
              price: _source?.variants[0]?.price,
              title: _source?.title,
              _id: _source?.id,
              ProductName: _source?.title,
              ProductSku: _source?.variants[0]?.sku,
              ProductUrl: productUrl,
              mediaUrl: _source?.image?.src,
              productAmount: _source?.variants[0]?.price,
              productDesc: description,
              productOpt: _source?.options,
              variants: _source?.variants,
              is_active: true,
              tags: _source?.tags
            });
          });
          // this.setState({ sku: loadSku });
          // arrsku = loadSku;
        })
        .catch(function (error) {
        });
    }
  };


  handleMultiSelect = (e, options) => {
    if (options.action === "remove-value") {
      this.props.getSku(e, options.removedValue);
      if (this.props.replace) {
        this.setState({
          replaceSku: e
        });
      } else {
        this.setState({
          sku: e
        });
      }
    }

    if (options.action === "select-option") {
      if (this.props.replace) {
        if (this.props.skuArr.length === 0) {
          this.props.skuData?.find((dt) => {
            if (e[e.length - 1]?.ProductSku == dt.ProductSku)
              notification.error({
                message: 'Product is Already Added',
                className: 'toast-error',
              });
          })
          var matchedSku = null;
          e?.forEach(element => {
            this.props.skuData?.forEach(ele => {
              if (element.ProductSku === ele.ProductSku) {
                matchedSku = ele.ProductSku;
              }
            })
          })


          this.props.getSku(e, "");
          e.map((item) => {

            var found = this.props.skuData?.filter((dt) => {
              return dt.ProductSku == item.ProductSku
            })

            if (!(item?.ProductSku == matchedSku) && !(found?.length > 0)) {
              this.setState({
                replaceSku: [...this.props.replaceSku, item]
              });
            }

          })
        } else {
          notification.error({
            message: 'One SKU Product Is Already Added',
            className: 'toast-error',
          });
        }
      } else {

        this.props.skuData?.find((dt) => {
          if (e[e.length - 1]?.ProductSku == dt.ProductSku)
            notification.error({
              message: 'Product is Already Added',
              className: 'toast-error',
            });
        })
        var matchedSku = null;
        e?.forEach(element => {
          this.props.skuData?.forEach(ele => {
            if (element.ProductSku === ele.ProductSku) {
              matchedSku = ele.ProductSku;
            }
          })
        })


        this.props.getSku(e, "");
        e.map((item) => {

          var found = this.props.skuData?.filter((dt) => {
            return dt.ProductSku == item.ProductSku
          })

          if (!(item?.ProductSku == matchedSku) && !(found?.length > 0)) {
            this.setState({
              sku: [...this.state.sku, item]
            });
          }

        })
      }
    }

    if (options.action === "clear") {
      this.props.getSku([]);
      this.setState({
        replaceSku: [],
        sku: []
      });
    }

  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData('text');
    await this.smartSearchFilter(getData);
  };

  formatOptionLabel = ({ label, title, price }) => (
    <div style={{ display: 'flex' }}>
      <div>{label}</div>&nbsp;-&nbsp;
      <div style={{ marginLeft: '10px' }}>{title}</div>&nbsp;-&nbsp;
      <div style={{ marginLeft: '10px' }}>${price}</div>
    </div>
  );


  render() {
    if (this.props.skuNull) {
      this.props.setSkuNull(false)
      this.setState({
        sku: ([])
      });
    }

    return (
      <React.Fragment>
        <div onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            menuPlacement={this.props.page == 'linkedProduct' ? 'top' : 'bottom'}
            isMulti
            cacheOptions
            defaultOptions
            // delimiter=","
            loadOptions={this.loadOptions}
            placeholder="Search By SKU & Title"
            name={'productsku'}
            // defaultValue={this.state.sku}
            value={this.props.replaceSku ? this.props.replaceSku : this.state.sku}
            onChange={(e, options) => {
              this.handleMultiSelect(e, options);
            }}
            formatOptionLabel={this.formatOptionLabel}
          />
        </div>
        {/* <div className="row mt-3">
          {this.state.sku &&
            this.state.sku.map((item, i) => {
              return (
                <>
                  <div className="col-6 col-md-3 col-lg-4 col-xl-3">
                    <div className="product-box-main">
                      <div className="product-content-top">
                        <div className="product-image">
                          <img
                            src={item.mediaUrl}
                            alt={item.title}
                            className="img-fluid"
                          />
                        </div>
                        <p>{item.title}</p>
                      </div>

                      <div className="product-content">
                        <h4>{item.price}</h4>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div> */}
      </React.Fragment>
    );
  }
}

export default AsyncProduct;
