import React, { useEffect, useState } from 'react';
import { useStepsForm } from 'sunflower-antd';
import { Button, Form, Input, message, InputNumber, Spin, Select } from 'antd';
import '../../css/feeStructure.scss';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import Swal from "sweetalert2";
import { getFeeStructure, saveFeeStructure } from '../../redux/actions/feestructure.action';
import { saveStoreSetup } from '../../redux/actions/storeSetup.action';
import { validateUser } from '../../redux/actions/login.action';

function StoreSetup() {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [storeData, setStoreData] = useState();

  const {
    validate
  } = useSelector((state) => {
    return state;
  });


  // useEffect(() => {
  //   dispatch(getFeeStructure()).then((res) => {
  //     if (res.payload.success) {
  //       setLoading(false);
  //       setStoreData(res.payload?.message?.fee_structure)
  //     }
  //     else {
  //       setLoading(false);
  //       console.log(res, "error");
  //     }
  //   });
  // }, []);

  const onFinish = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "You want to save these changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setSpinner(true);
        dispatch(saveStoreSetup(storeData)).then((res) => {
          if (res?.payload?.success) {
            setLoading(true)
            dispatch(validateUser(token));
            setLoading(false)
            // setStoreData(validate?.payload?.message?.app ? 'on' : 'off')
            setSpinner(false);
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
          } else {
            setSpinner(false);
            notification.error({
              message: "Something went wrong",
              className: "toast-error",
            });
            console.log(res?.response, "error");
          }
        })
      }
    });
  };


  const handleChange = (e) => {
    setStoreData(e)
  };

  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Store Setup</h1>
      </div>
      <div className="container p-0 ml-0">

        <div className="row fee-structure">
          <div className="profile_box_main col-md-8 col-lg-6 mb-20">
            <div className="stream-box-white h-10 mb-0">
              {!loading ? (
                <>
                  <div className='row mb-3'>
                    <div className='col-4 fee-label'>Store Public</div>
                    <div className='col-8 d-flex justify-content-end'>

                      <Select
                        size='large'
                        defaultValue={validate?.payload?.message?.app ? 'on' : 'off'}
                        onChange={handleChange}
                        style={{
                          width: '100%',
                        }}
                        value={storeData}
                        options={[
                          {
                            value: 'on',
                            label: 'Active',
                          },
                          {
                            value: 'off',
                            label: 'Deactive',
                          }
                        ]}
                      /></div>
                  </div>
                  <div className="mt-9 d-flex justify-content-end">
                    <Button
                      type="primary"
                      // htmlType="submit"
                      onClick={onFinish}
                      className="default-btn login-btn ml-0 mr-0"
                      loading={spinner}
                      // disabled={spinner}
                      disabled={spinner}

                    >
                      Save
                    </Button>
                  </div>
                </>
              ) :
                (
                  <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                  </span>
                )}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default StoreSetup;