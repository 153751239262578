import {
  BAN_USER_LIST,
  USER_COMMENT_LIST,
  USER_REVIEW_COMMENT_LIST,
  SEARCH_VIDEO_TITLE_FILTER,
  SEARCH_USER_FILTER, SEARCH_REVIEW_VIDEO_TITLE_FILTER, SEARCH_REVIEW_USER_FILTER
} from '../types/types';
import axios from 'axios';

export const bannedUsers = (ban_type, user_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `users/chat/getbanuserlist?page=1&limit=50`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      ban_type,
      user_id
    },
  })
    .then((response) => {
      dispatch({
        type: BAN_USER_LIST,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};

export const usersComments = (page, limit, start_date, end_date, event, user, ban_type, user_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `chat/list`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    params: {
      limit,
      page
    },
    data: {
      from_date: start_date,
      to_date: end_date,
      event,
      user
      // ban_type,
      // user_id
    },
  })
    .then((response) => {
      dispatch({
        type: USER_COMMENT_LIST,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};
export const usersReviewComments = (page, limit, start_date, end_date, review, user, ban_type, user_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `reviewchat/list`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    params: {
      limit,
      page
    },
    data: {
      from_date: start_date,
      to_date: end_date,
      review,
      user
      // ban_type,
      // user_id
    },
  })
    .then((response) => {
      dispatch({
        type: USER_REVIEW_COMMENT_LIST,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};

export const searchTitleFilter = (title, page, limit, startDate, endDate) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `chat/filterevents`,
    params: {
      limit,
      page
    },
    data: {
      title,
      from_date: startDate,
      to_date: endDate,
    },
  })
    .then((response) => {
      dispatch({
        type: SEARCH_VIDEO_TITLE_FILTER,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};

export const searchUserFilter = (name, page, limit, startDate, endDate, event) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `chat/filterusers`,
    params: {
      limit,
      page
    },
    data: {
      name,
      event,
      from_date: startDate,
      to_date: endDate,
    },
  })
    .then((response) => {
      dispatch({
        type: SEARCH_USER_FILTER,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};

export const searchReviewTitleFilter = (title, page, limit, startDate, endDate) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `reviewchat/filterreviews`,
    params: {
      limit,
      page
    },
    data: {
      title,
      from_date: startDate,
      to_date: endDate,
    },
  })
    .then((response) => {
      dispatch({
        type: SEARCH_REVIEW_VIDEO_TITLE_FILTER,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};

export const searchReviewUserFilter = (name, page, limit, startDate, endDate, review) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `reviewchat/filterusers`,
    params: {
      limit,
      page
    },
    data: {
      name,
      review,
      from_date: startDate,
      to_date: endDate,
    },
  })
    .then((response) => {
      dispatch({
        type: SEARCH_REVIEW_USER_FILTER,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};